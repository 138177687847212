@charset "UTF-8";
.icon { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.icon-air-conditioning:before { content: ""; }

.icon-airplane:before { content: ""; }

.icon-arrow-down:before { content: ""; }

.icon-arrow-left:before { content: ""; }

.icon-arrow-right:before { content: ""; }

.icon-arrow-up:before { content: ""; }

.icon-bath:before { content: ""; }

.icon-bathtub:before { content: ""; }

.icon-bed:before { content: ""; }

.icon-bunk:before { content: ""; }

.icon-bus:before { content: ""; }

.icon-calendar:before { content: ""; }

.icon-calendar-checked:before { content: ""; }

.icon-call:before { content: ""; }

.icon-car:before { content: ""; }

.icon-cereals:before { content: ""; }

.icon-concierge:before { content: ""; }

.icon-cookies:before { content: ""; }

.icon-cot:before { content: ""; }

.icon-couple:before { content: ""; }

.icon-croissant:before { content: ""; }

.icon-desk:before { content: ""; }

.icon-double-arrow-down:before { content: ""; }

.icon-double-arrow-left:before { content: ""; }

.icon-double-arrow-right:before { content: ""; }

.icon-double-arrow-up:before { content: ""; }

.icon-envelope:before { content: ""; }

.icon-facebook:before { content: ""; }

.icon-familiar-group-of-three:before { content: ""; }

.icon-family:before { content: ""; }

.icon-father-with-his-daughter-and-baby:before { content: ""; }

.icon-foursquare:before { content: ""; }

.icon-google-plus:before { content: ""; }

.icon-hairdryer:before { content: ""; }

.icon-hotel:before { content: ""; }

.icon-instagram:before { content: ""; }

.icon-kid:before { content: ""; }

.icon-laundry:before { content: ""; }

.icon-lift:before { content: ""; }

.icon-man:before { content: ""; }

.icon-minibar:before { content: ""; }

.icon-minus-circle:before { content: ""; }

.icon-mobile:before { content: ""; }

.icon-moka:before { content: ""; }

.icon-mother-with-son:before { content: ""; }

.icon-no-smoking:before { content: ""; }

.icon-oven:before { content: ""; }

.icon-parking:before { content: ""; }

.icon-phone:before { content: ""; }

.icon-pin:before { content: ""; }

.icon-pinterest:before { content: ""; }

.icon-plant:before { content: ""; }

.icon-plus-circle:before { content: ""; }

.icon-refresh:before { content: ""; }

.icon-reservation:before { content: ""; }

.icon-restaurant:before { content: ""; }

.icon-room-service:before { content: ""; }

.icon-safebox:before { content: ""; }

.icon-sales:before { content: ""; }

.icon-satellite-dish:before { content: ""; }

.icon-ship:before { content: ""; }

.icon-shower:before { content: ""; }

.icon-ssl:before { content: ""; }

.icon-sun:before { content: ""; }

.icon-tea-cup:before { content: ""; }

.icon-three-persons:before { content: ""; }

.icon-towel:before { content: ""; }

.icon-train:before { content: ""; }

.icon-tree:before { content: ""; }

.icon-tripadvisor:before { content: ""; }

.icon-tv:before { content: ""; }

.icon-twitter:before { content: ""; }

.icon-vacuum:before { content: ""; }

.icon-wedding:before { content: ""; }

.icon-whatsapp:before { content: ""; }

.icon-whatsapp-official2 .path1:before { content: ""; color: #ededed; }

.icon-whatsapp-official2 .path2:before { content: ""; margin-left: -1em; color: #55cd6c; }

.icon-whatsapp-official2 .path3:before { content: ""; margin-left: -1em; color: #fefefe; }

.icon-wifi:before { content: ""; }

.icon-wine:before { content: ""; }

.icon-youtube:before { content: ""; }

html { font-family: sans-serif; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

body { margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary { display: block; }

audio, canvas, progress, video { display: inline-block; vertical-align: baseline; }

audio:not([controls]) { display: none; height: 0; }

[hidden], template { display: none; }

a { background-color: transparent; }

a:active, a:hover { outline: 0; }

abbr[title] { border-bottom: 1px dotted; }

b, strong { font-weight: bold; }

dfn { font-style: italic; }

h1 { font-size: 2em; margin: 0.67em 0; }

mark { background: #ff0; color: #000; }

small { font-size: 80%; }

sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }

sup { top: -0.5em; }

sub { bottom: -0.25em; }

img { border: 0; }

svg:not(:root) { overflow: hidden; }

figure { margin: 1em 40px; }

hr { box-sizing: content-box; height: 0; }

pre { overflow: auto; }

code, kbd, pre, samp { font-family: monospace, monospace; font-size: 1em; }

button, input, optgroup, select, textarea { color: inherit; font: inherit; margin: 0; }

button { overflow: visible; }

button, select { text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] { -webkit-appearance: button; cursor: pointer; }

button[disabled], html input[disabled] { cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }

input { line-height: normal; }

input[type="checkbox"], input[type="radio"] { box-sizing: border-box; padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button { height: auto; }

input[type="search"] { -webkit-appearance: textfield; box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }

legend { border: 0; padding: 0; }

textarea { overflow: auto; }

optgroup { font-weight: bold; }

table { border-collapse: collapse; border-spacing: 0; }

td, th { padding: 0; }

@media print { *, *:before, *:after { background: transparent !important; color: #000 !important; box-shadow: none !important; text-shadow: none !important; }
  a, a:visited { text-decoration: underline; }
  a[href]:after { content: " (" attr(href) ")"; }
  abbr[title]:after { content: " (" attr(title) ")"; }
  a[href^="#"]:after, a[href^="javascript:"]:after { content: ""; }
  pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
  thead { display: table-header-group; }
  tr, img { page-break-inside: avoid; }
  img { max-width: 100% !important; }
  p, h2, h3 { orphans: 3; widows: 3; }
  h2, h3 { page-break-after: avoid; }
  .navbar { display: none; }
  .btn > .caret, .dropup > .btn > .caret { border-top-color: #000 !important; }
  .label { border: 1px solid #000; }
  .table { border-collapse: collapse !important; }
  .table td, .table th { background-color: #fff !important; }
  .table-bordered th, .table-bordered td { border: 1px solid #ddd !important; } }

* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

html { font-size: 10px; -webkit-tap-highlight-color: transparent; }

body { font-family: 'Cambria', serif; line-height: 1.6666666667; color: #636363; background-color: #f2ede9; }

input, button, select, textarea { font-family: inherit; font-size: inherit; line-height: inherit; }

a { color: #005593; text-decoration: none; }

a:hover, a:focus { color: #002946; text-decoration: none; }

a:focus { outline: thin dotted; outline: 5px auto -webkit-focus-ring-color; outline-offset: -2px; }

figure { margin: 0; }

img { vertical-align: middle; }

.img-responsive { display: block; max-width: 100%; height: auto; }

.img-rounded { border-radius: 6px; }

.img-thumbnail { padding: 4px; line-height: 1.6666666667; background-color: #f2ede9; border: 1px solid #ddd; border-radius: 4px; -webkit-transition: all 0.2s ease-in-out; -o-transition: all 0.2s ease-in-out; transition: all 0.2s ease-in-out; display: inline-block; max-width: 100%; height: auto; }

.img-circle { border-radius: 50%; }

hr { margin-top: 28px; margin-bottom: 28px; border: 0; border-top: 1px solid #CDBAAB; }

.sr-only { position: absolute; width: 1px; height: 1px; margin: -1px; padding: 0; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus { position: static; width: auto; height: auto; margin: 0; overflow: visible; clip: auto; }

[role="button"] { cursor: pointer; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { font-family: inherit; font-weight: 400; line-height: 1.1; color: inherit; }

h1 small, h1 .small, h2 small, h2 .small, h3 small, h3 .small, h4 small, h4 .small, h5 small, h5 .small, h6 small, h6 .small, .h1 small, .h1 .small, .h2 small, .h2 .small, .h3 small, .h3 .small, .h4 small, .h4 .small, .h5 small, .h5 .small, .h6 small, .h6 .small { font-weight: normal; line-height: 1; color: #777777; }

h1, .h1, h2, .h2, h3, .h3 { margin-top: 28px; margin-bottom: 14px; }

h1 small, h1 .small, .h1 small, .h1 .small, h2 small, h2 .small, .h2 small, .h2 .small, h3 small, h3 .small, .h3 small, .h3 .small { font-size: 65%; }

h4, .h4, h5, .h5, h6, .h6 { margin-top: 14px; margin-bottom: 14px; }

h4 small, h4 .small, .h4 small, .h4 .small, h5 small, h5 .small, .h5 small, .h5 .small, h6 small, h6 .small, .h6 small, .h6 .small { font-size: 75%; }

h1, .h1 { font-size: 43.4444444444px; }

h2, .h2 { font-size: 34.3607722801px; }

h3, .h3 { font-size: 27.1763786321px; }

h4, .h4 { font-size: 21.4941488956px; }

h5, .h5 { font-size: 27.1763786321px; }

h6, .h6 { font-size: 13.4455195879px; }

p { margin: 0 0 14px; }

.lead { margin-bottom: 28px; font-size: 19px; font-weight: 300; line-height: 1.4; }

@media (min-width: 768px) { .lead { font-size: 25.5px; } }

small, .small { font-size: 88%; }

mark, .mark { background-color: #fcf8e3; padding: .2em; }

.text-left { text-align: left; }

.text-right { text-align: right; }

.text-center { text-align: center; }

.text-justify { text-align: justify; }

.text-nowrap { white-space: nowrap; }

.text-lowercase { text-transform: lowercase; }

.text-uppercase, .initialism { text-transform: uppercase; }

.text-capitalize { text-transform: capitalize; }

.text-muted { color: #777777; }

.text-primary { color: #005593; }

a.text-primary:hover, a.text-primary:focus { color: #003760; }

.text-success { color: #3c763d; }

a.text-success:hover, a.text-success:focus { color: #2b542c; }

.text-info { color: #31708f; }

a.text-info:hover, a.text-info:focus { color: #245269; }

.text-warning { color: #8a6d3b; }

a.text-warning:hover, a.text-warning:focus { color: #66512c; }

.text-danger { color: #a94442; }

a.text-danger:hover, a.text-danger:focus { color: #843534; }

.bg-primary { color: #fff; }

.bg-primary { background-color: #005593; }

a.bg-primary:hover, a.bg-primary:focus { background-color: #003760; }

.bg-success { background-color: #dff0d8; }

a.bg-success:hover, a.bg-success:focus { background-color: #c1e2b3; }

.bg-info { background-color: #d9edf7; }

a.bg-info:hover, a.bg-info:focus { background-color: #afd9ee; }

.bg-warning { background-color: #fcf8e3; }

a.bg-warning:hover, a.bg-warning:focus { background-color: #f7ecb5; }

.bg-danger { background-color: #f2dede; }

a.bg-danger:hover, a.bg-danger:focus { background-color: #e4b9b9; }

ul, ol { margin-top: 0; margin-bottom: 14px; }

ul ul, ul ol, ol ul, ol ol { margin-bottom: 0; }

.list-unstyled { padding-left: 0; list-style: none; }

.list-inline { padding-left: 0; list-style: none; margin-left: -5px; }

.list-inline > li { display: inline-block; padding-left: 5px; padding-right: 5px; }

dl { margin-top: 0; margin-bottom: 28px; }

dt, dd { line-height: 1.6666666667; }

dt { font-weight: bold; }

dd { margin-left: 0; }

.dl-horizontal dd:before, .dl-horizontal dd:after { content: " "; display: table; }

.dl-horizontal dd:after { clear: both; }

@media (min-width: 768px) { .dl-horizontal dt { float: left; width: 160px; clear: left; text-align: right; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
  .dl-horizontal dd { margin-left: 180px; } }

abbr[title], abbr[data-original-title] { cursor: help; border-bottom: 1px dotted #777777; }

.initialism { font-size: 90%; }

blockquote { padding: 14px 28px; margin: 0 0 28px; font-size: 21.25px; border-left: 5px solid #eeeeee; }

blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child { margin-bottom: 0; }

blockquote footer, blockquote small, blockquote .small { display: block; font-size: 80%; line-height: 1.6666666667; color: #777777; }

blockquote footer:before, blockquote small:before, blockquote .small:before { content: '\2014 \00A0'; }

.blockquote-reverse, blockquote.pull-right { padding-right: 15px; padding-left: 0; border-right: 5px solid #eeeeee; border-left: 0; text-align: right; }

.blockquote-reverse footer:before, .blockquote-reverse small:before, .blockquote-reverse .small:before, blockquote.pull-right footer:before, blockquote.pull-right small:before, blockquote.pull-right .small:before { content: ''; }

.blockquote-reverse footer:after, .blockquote-reverse small:after, .blockquote-reverse .small:after, blockquote.pull-right footer:after, blockquote.pull-right small:after, blockquote.pull-right .small:after { content: '\00A0 \2014'; }

address { margin-bottom: 28px; font-style: normal; line-height: 1.6666666667; }

code, kbd, pre, samp { font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code { padding: 2px 4px; font-size: 90%; color: #c7254e; background-color: #f9f2f4; border-radius: 4px; }

kbd { padding: 2px 4px; font-size: 90%; color: #fff; background-color: #333; border-radius: 3px; box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }

kbd kbd { padding: 0; font-size: 100%; font-weight: bold; box-shadow: none; }

pre { display: block; padding: 13.5px; margin: 0 0 14px; font-size: 16px; line-height: 1.6666666667; word-break: break-all; word-wrap: break-word; color: #333333; background-color: #f5f5f5; border: 1px solid #ccc; border-radius: 4px; }

pre code { padding: 0; font-size: inherit; color: inherit; white-space: pre-wrap; background-color: transparent; border-radius: 0; }

.pre-scrollable { max-height: 340px; overflow-y: scroll; }

table { background-color: transparent; }

caption { padding-top: 8px; padding-bottom: 8px; color: #777777; text-align: left; }

th { text-align: left; }

.table { width: 100%; max-width: 100%; margin-bottom: 28px; }

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td { padding: 8px; line-height: 1.6666666667; vertical-align: top; border-top: 1px solid #ddd; }

.table > thead > tr > th { vertical-align: bottom; border-bottom: 2px solid #ddd; }

.table > caption + thead > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > th, .table > thead:first-child > tr:first-child > td { border-top: 0; }

.table > tbody + tbody { border-top: 2px solid #ddd; }

.table .table { background-color: #f2ede9; }

.table-condensed > thead > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > th, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > th, .table-condensed > tfoot > tr > td { padding: 5px; }

.table-bordered { border: 1px solid #ddd; }

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > tfoot > tr > td { border: 1px solid #ddd; }

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td { border-bottom-width: 2px; }

.table-striped > tbody > tr:nth-of-type(odd) { background-color: #f9f9f9; }

.table-hover > tbody > tr:hover { background-color: #f5f5f5; }

table col[class*="col-"] { position: static; float: none; display: table-column; }

table td[class*="col-"], table th[class*="col-"] { position: static; float: none; display: table-cell; }

.table > thead > tr > td.active, .table > thead > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th, .table > tbody > tr > td.active, .table > tbody > tr > th.active, .table > tbody > tr.active > td, .table > tbody > tr.active > th, .table > tfoot > tr > td.active, .table > tfoot > tr > th.active, .table > tfoot > tr.active > td, .table > tfoot > tr.active > th { background-color: #f5f5f5; }

.table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr.active:hover > th { background-color: #e8e8e8; }

.table > thead > tr > td.success, .table > thead > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr > td.success, .table > tbody > tr > th.success, .table > tbody > tr.success > td, .table > tbody > tr.success > th, .table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .table > tfoot > tr.success > td, .table > tfoot > tr.success > th { background-color: #dff0d8; }

.table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr.success:hover > th { background-color: #d0e9c6; }

.table > thead > tr > td.info, .table > thead > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th, .table > tbody > tr > td.info, .table > tbody > tr > th.info, .table > tbody > tr.info > td, .table > tbody > tr.info > th, .table > tfoot > tr > td.info, .table > tfoot > tr > th.info, .table > tfoot > tr.info > td, .table > tfoot > tr.info > th { background-color: #d9edf7; }

.table-hover > tbody > tr > td.info:hover, .table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr.info:hover > th { background-color: #c4e3f3; }

.table > thead > tr > td.warning, .table > thead > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr > td.warning, .table > tbody > tr > th.warning, .table > tbody > tr.warning > td, .table > tbody > tr.warning > th, .table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning, .table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th { background-color: #fcf8e3; }

.table-hover > tbody > tr > td.warning:hover, .table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr.warning:hover > th { background-color: #faf2cc; }

.table > thead > tr > td.danger, .table > thead > tr > th.danger, .table > thead > tr.danger > td, .table > thead > tr.danger > th, .table > tbody > tr > td.danger, .table > tbody > tr > th.danger, .table > tbody > tr.danger > td, .table > tbody > tr.danger > th, .table > tfoot > tr > td.danger, .table > tfoot > tr > th.danger, .table > tfoot > tr.danger > td, .table > tfoot > tr.danger > th { background-color: #f2dede; }

.table-hover > tbody > tr > td.danger:hover, .table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr.danger:hover > th { background-color: #ebcccc; }

.table-responsive { overflow-x: auto; min-height: 0.01%; }

@media screen and (max-width: 767px) { .table-responsive { width: 100%; margin-bottom: 21px; overflow-y: hidden; -ms-overflow-style: -ms-autohiding-scrollbar; border: 1px solid #ddd; }
  .table-responsive > .table { margin-bottom: 0; }
  .table-responsive > .table > thead > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > tfoot > tr > td { white-space: nowrap; }
  .table-responsive > .table-bordered { border: 0; }
  .table-responsive > .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child { border-left: 0; }
  .table-responsive > .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child { border-right: 0; }
  .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > td { border-bottom: 0; } }

fieldset { padding: 0; margin: 0; border: 0; min-width: 0; }

legend { display: block; width: 100%; padding: 0; margin-bottom: 28px; font-size: 25.5px; line-height: inherit; color: #005593; border: 0; border-bottom: 1px solid #dfd3c8; }

label { display: inline-block; max-width: 100%; margin-bottom: 5px; font-weight: bold; }

input[type="search"] { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

input[type="radio"], input[type="checkbox"] { margin: 4px 0 0; margin-top: 1px \9; line-height: normal; }

input[type="file"] { display: block; }

input[type="range"] { display: block; width: 100%; }

select[multiple], select[size] { height: auto; }

input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus { outline: thin dotted; outline: 5px auto -webkit-focus-ring-color; outline-offset: -2px; }

output { display: block; padding-top: 7px; font-size: 17px; line-height: 1.6666666667; color: #555555; }

.form-control { display: block; width: 100%; height: 42px; padding: 6px 12px; font-size: 17px; line-height: 1.6666666667; color: #555555; background-color: #fff; background-image: none; border: 1px solid #e6e5e5; border-radius: 0; -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }

.form-control:focus { border-color: #005593; outline: 0; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 85, 147, 0.6); }

.form-control::-moz-placeholder { color: #999; opacity: 1; }

.form-control:-ms-input-placeholder { color: #999; }

.form-control::-webkit-input-placeholder { color: #999; }

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control { background-color: #eeeeee; opacity: 1; }

.form-control[disabled], fieldset[disabled] .form-control { cursor: not-allowed; }

textarea.form-control { height: auto; }

input[type="search"] { -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) { input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control { line-height: 42px; }
  input[type="date"].input-sm, .input-group-sm > input[type="date"].form-control, .input-group-sm > input[type="date"].input-group-addon, .input-group-sm > .input-group-btn > input[type="date"].btn, .input-group-sm input[type="date"], input[type="time"].input-sm, .input-group-sm > input[type="time"].form-control, .input-group-sm > input[type="time"].input-group-addon, .input-group-sm > .input-group-btn > input[type="time"].btn, .input-group-sm input[type="time"], input[type="datetime-local"].input-sm, .input-group-sm > input[type="datetime-local"].form-control, .input-group-sm > input[type="datetime-local"].input-group-addon, .input-group-sm > .input-group-btn > input[type="datetime-local"].btn, .input-group-sm input[type="datetime-local"], input[type="month"].input-sm, .input-group-sm > input[type="month"].form-control, .input-group-sm > input[type="month"].input-group-addon, .input-group-sm > .input-group-btn > input[type="month"].btn, .input-group-sm input[type="month"] { line-height: 34px; }
  input[type="date"].input-lg, .input-group-lg > input[type="date"].form-control, .input-group-lg > input[type="date"].input-group-addon, .input-group-lg > .input-group-btn > input[type="date"].btn, .input-group-lg input[type="date"], input[type="time"].input-lg, .input-group-lg > input[type="time"].form-control, .input-group-lg > input[type="time"].input-group-addon, .input-group-lg > .input-group-btn > input[type="time"].btn, .input-group-lg input[type="time"], input[type="datetime-local"].input-lg, .input-group-lg > input[type="datetime-local"].form-control, .input-group-lg > input[type="datetime-local"].input-group-addon, .input-group-lg > .input-group-btn > input[type="datetime-local"].btn, .input-group-lg input[type="datetime-local"], input[type="month"].input-lg, .input-group-lg > input[type="month"].form-control, .input-group-lg > input[type="month"].input-group-addon, .input-group-lg > .input-group-btn > input[type="month"].btn, .input-group-lg input[type="month"] { line-height: 52px; } }

.form-group { margin-bottom: 30px; }

.radio, .checkbox { position: relative; display: block; margin-top: 10px; margin-bottom: 10px; }

.radio label, .checkbox label { min-height: 28px; padding-left: 20px; margin-bottom: 0; font-weight: normal; cursor: pointer; }

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] { position: absolute; margin-left: -20px; margin-top: 4px \9; }

.radio + .radio, .checkbox + .checkbox { margin-top: -5px; }

.radio-inline, .checkbox-inline { position: relative; display: inline-block; padding-left: 20px; margin-bottom: 0; vertical-align: middle; font-weight: normal; cursor: pointer; }

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline { margin-top: 0; margin-left: 10px; }

input[type="radio"][disabled], input[type="radio"].disabled, fieldset[disabled] input[type="radio"], input[type="checkbox"][disabled], input[type="checkbox"].disabled, fieldset[disabled] input[type="checkbox"] { cursor: not-allowed; }

.radio-inline.disabled, fieldset[disabled] .radio-inline, .checkbox-inline.disabled, fieldset[disabled] .checkbox-inline { cursor: not-allowed; }

.radio.disabled label, fieldset[disabled] .radio label, .checkbox.disabled label, fieldset[disabled] .checkbox label { cursor: not-allowed; }

.form-control-static { padding-top: 7px; padding-bottom: 7px; margin-bottom: 0; min-height: 45px; }

.form-control-static.input-lg, .input-group-lg > .form-control-static.form-control, .input-group-lg > .form-control-static.input-group-addon, .input-group-lg > .input-group-btn > .form-control-static.btn, .form-control-static.input-sm, .input-group-sm > .form-control-static.form-control, .input-group-sm > .form-control-static.input-group-addon, .input-group-sm > .input-group-btn > .form-control-static.btn { padding-left: 0; padding-right: 0; }

.input-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn { height: 34px; padding: 5px 10px; font-size: 15px; line-height: 1.5; border-radius: 0; }

select.input-sm, .input-group-sm > select.form-control, .input-group-sm > select.input-group-addon, .input-group-sm > .input-group-btn > select.btn { height: 34px; line-height: 34px; }

textarea.input-sm, .input-group-sm > textarea.form-control, .input-group-sm > textarea.input-group-addon, .input-group-sm > .input-group-btn > textarea.btn, select[multiple].input-sm, .input-group-sm > select[multiple].form-control, .input-group-sm > select[multiple].input-group-addon, .input-group-sm > .input-group-btn > select[multiple].btn { height: auto; }

.form-group-sm .form-control { height: 34px; padding: 5px 10px; font-size: 15px; line-height: 1.5; border-radius: 0; }

.form-group-sm select.form-control { height: 34px; line-height: 34px; }

.form-group-sm textarea.form-control, .form-group-sm select[multiple].form-control { height: auto; }

.form-group-sm .form-control-static { height: 34px; min-height: 43px; padding: 6px 10px; font-size: 15px; line-height: 1.5; }

.input-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn { height: 52px; padding: 10px 16px; font-size: 22px; line-height: 1.3333333; border-radius: 0; }

select.input-lg, .input-group-lg > select.form-control, .input-group-lg > select.input-group-addon, .input-group-lg > .input-group-btn > select.btn { height: 52px; line-height: 52px; }

textarea.input-lg, .input-group-lg > textarea.form-control, .input-group-lg > textarea.input-group-addon, .input-group-lg > .input-group-btn > textarea.btn, select[multiple].input-lg, .input-group-lg > select[multiple].form-control, .input-group-lg > select[multiple].input-group-addon, .input-group-lg > .input-group-btn > select[multiple].btn { height: auto; }

.form-group-lg .form-control { height: 52px; padding: 10px 16px; font-size: 22px; line-height: 1.3333333; border-radius: 0; }

.form-group-lg select.form-control { height: 52px; line-height: 52px; }

.form-group-lg textarea.form-control, .form-group-lg select[multiple].form-control { height: auto; }

.form-group-lg .form-control-static { height: 52px; min-height: 50px; padding: 11px 16px; font-size: 22px; line-height: 1.3333333; }

.has-feedback { position: relative; }

.has-feedback .form-control { padding-right: 52.5px; }

.form-control-feedback { position: absolute; top: 0; right: 0; z-index: 2; display: block; width: 42px; height: 42px; line-height: 42px; text-align: center; pointer-events: none; }

.input-lg + .form-control-feedback, .input-group-lg > .form-control + .form-control-feedback, .input-group-lg > .input-group-addon + .form-control-feedback, .input-group-lg > .input-group-btn > .btn + .form-control-feedback, .input-group-lg + .form-control-feedback, .form-group-lg .form-control + .form-control-feedback { width: 52px; height: 52px; line-height: 52px; }

.input-sm + .form-control-feedback, .input-group-sm > .form-control + .form-control-feedback, .input-group-sm > .input-group-addon + .form-control-feedback, .input-group-sm > .input-group-btn > .btn + .form-control-feedback, .input-group-sm + .form-control-feedback, .form-group-sm .form-control + .form-control-feedback { width: 34px; height: 34px; line-height: 34px; }

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label { color: #3c763d; }

.has-success .form-control { border-color: #3c763d; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-success .form-control:focus { border-color: #2b542c; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.has-success .input-group-addon { color: #3c763d; border-color: #3c763d; background-color: #dff0d8; }

.has-success .form-control-feedback { color: #3c763d; }

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label { color: #8a6d3b; }

.has-warning .form-control { border-color: #8a6d3b; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-warning .form-control:focus { border-color: #66512c; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.has-warning .input-group-addon { color: #8a6d3b; border-color: #8a6d3b; background-color: #fcf8e3; }

.has-warning .form-control-feedback { color: #8a6d3b; }

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label { color: #a94442; }

.has-error .form-control { border-color: #a94442; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-error .form-control:focus { border-color: #843534; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.has-error .input-group-addon { color: #a94442; border-color: #a94442; background-color: #f2dede; }

.has-error .form-control-feedback { color: #a94442; }

.has-feedback label ~ .form-control-feedback { top: 33px; }

.has-feedback label.sr-only ~ .form-control-feedback { top: 0; }

.help-block { display: block; margin-top: 5px; margin-bottom: 10px; color: #a3a3a3; }

@media (min-width: 768px) { .form-inline .form-group { display: inline-block; margin-bottom: 0; vertical-align: middle; }
  .form-inline .form-control { display: inline-block; width: auto; vertical-align: middle; }
  .form-inline .form-control-static { display: inline-block; }
  .form-inline .input-group { display: inline-table; vertical-align: middle; }
  .form-inline .input-group .input-group-addon, .form-inline .input-group .input-group-btn, .form-inline .input-group .form-control { width: auto; }
  .form-inline .input-group > .form-control { width: 100%; }
  .form-inline .control-label { margin-bottom: 0; vertical-align: middle; }
  .form-inline .radio, .form-inline .checkbox { display: inline-block; margin-top: 0; margin-bottom: 0; vertical-align: middle; }
  .form-inline .radio label, .form-inline .checkbox label { padding-left: 0; }
  .form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] { position: relative; margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback { top: 0; } }

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline { margin-top: 0; margin-bottom: 0; padding-top: 7px; }

.form-horizontal .radio, .form-horizontal .checkbox { min-height: 35px; }

.form-horizontal .form-group { margin-left: -19px; margin-right: -19px; }

.form-horizontal .form-group:before, .form-horizontal .form-group:after { content: " "; display: table; }

.form-horizontal .form-group:after { clear: both; }

@media (min-width: 768px) { .form-horizontal .control-label { text-align: right; margin-bottom: 0; padding-top: 7px; } }

.form-horizontal .has-feedback .form-control-feedback { right: 19px; }

@media (min-width: 768px) { .form-horizontal .form-group-lg .control-label { padding-top: 14.333333px; font-size: 22px; } }

@media (min-width: 768px) { .form-horizontal .form-group-sm .control-label { padding-top: 6px; font-size: 15px; } }

.btn { display: inline-block; margin-bottom: 0; font-weight: normal; text-align: center; vertical-align: middle; touch-action: manipulation; cursor: pointer; background-image: none; border: 1px solid transparent; white-space: nowrap; padding: 6px 12px; font-size: 17px; line-height: 1.6666666667; border-radius: 0; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus { outline: thin dotted; outline: 5px auto -webkit-focus-ring-color; outline-offset: -2px; }

.btn:hover, .btn:focus, .btn.focus { color: #333; text-decoration: none; }

.btn:active, .btn.active { outline: 0; background-image: none; }

.btn.disabled, .btn[disabled], fieldset[disabled] .btn { cursor: not-allowed; opacity: 0.65; box-shadow: none; }

a.btn.disabled, fieldset[disabled] a.btn { pointer-events: none; }

.btn-default { color: #333; background-color: #fff; border-color: #ccc; }

.btn-default:focus, .btn-default.focus { color: #333; background-color: #e6e5e5; border-color: #8c8c8c; }

.btn-default:hover { color: #333; background-color: #e6e5e5; border-color: #adadad; }

.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle { color: #333; background-color: #e6e5e5; border-color: #adadad; }

.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open > .btn-default.dropdown-toggle:hover, .open > .btn-default.dropdown-toggle:focus, .open > .btn-default.dropdown-toggle.focus { color: #333; background-color: #d4d4d4; border-color: #8c8c8c; }

.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle { background-image: none; }

.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, .btn-default[disabled]:active, .btn-default[disabled].active, fieldset[disabled] .btn-default, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active { background-color: #fff; border-color: #ccc; }

.btn-default .badge { color: #fff; background-color: #333; }

.btn-primary { color: #fff; background-color: #005593; border-color: #004679; }

.btn-primary:focus, .btn-primary.focus { color: #fff; background-color: #003760; border-color: black; }

.btn-primary:hover { color: #fff; background-color: #003760; border-color: #00233c; }

.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle { color: #fff; background-color: #003760; border-color: #00233c; }

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle.focus { color: #fff; background-color: #00233c; border-color: black; }

.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle { background-image: none; }

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active { background-color: #005593; border-color: #004679; }

.btn-primary .badge { color: #005593; background-color: #fff; }

.btn-success { color: #fff; background-color: #5cb85c; border-color: #4cae4c; }

.btn-success:focus, .btn-success.focus { color: #fff; background-color: #449d44; border-color: #255625; }

.btn-success:hover { color: #fff; background-color: #449d44; border-color: #398439; }

.btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle { color: #fff; background-color: #449d44; border-color: #398439; }

.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus, .open > .btn-success.dropdown-toggle:hover, .open > .btn-success.dropdown-toggle:focus, .open > .btn-success.dropdown-toggle.focus { color: #fff; background-color: #398439; border-color: #255625; }

.btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle { background-image: none; }

.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active, fieldset[disabled] .btn-success, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success.focus, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success.active { background-color: #5cb85c; border-color: #4cae4c; }

.btn-success .badge { color: #5cb85c; background-color: #fff; }

.btn-info { color: #fff; background-color: #5bc0de; border-color: #46b8da; }

.btn-info:focus, .btn-info.focus { color: #fff; background-color: #31b0d5; border-color: #1b6d85; }

.btn-info:hover { color: #fff; background-color: #31b0d5; border-color: #269abc; }

.btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle { color: #fff; background-color: #31b0d5; border-color: #269abc; }

.btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus, .open > .btn-info.dropdown-toggle:hover, .open > .btn-info.dropdown-toggle:focus, .open > .btn-info.dropdown-toggle.focus { color: #fff; background-color: #269abc; border-color: #1b6d85; }

.btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle { background-image: none; }

.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled].active, fieldset[disabled] .btn-info, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info.focus, fieldset[disabled] .btn-info:active, fieldset[disabled] .btn-info.active { background-color: #5bc0de; border-color: #46b8da; }

.btn-info .badge { color: #5bc0de; background-color: #fff; }

.btn-warning { color: #fff; background-color: #f0ad4e; border-color: #eea236; }

.btn-warning:focus, .btn-warning.focus { color: #fff; background-color: #ec971f; border-color: #985f0d; }

.btn-warning:hover { color: #fff; background-color: #ec971f; border-color: #d58512; }

.btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle { color: #fff; background-color: #ec971f; border-color: #d58512; }

.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus, .open > .btn-warning.dropdown-toggle:hover, .open > .btn-warning.dropdown-toggle:focus, .open > .btn-warning.dropdown-toggle.focus { color: #fff; background-color: #d58512; border-color: #985f0d; }

.btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle { background-image: none; }

.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled].active, fieldset[disabled] .btn-warning, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning.focus, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning.active { background-color: #f0ad4e; border-color: #eea236; }

.btn-warning .badge { color: #f0ad4e; background-color: #fff; }

.btn-danger { color: #fff; background-color: #d9534f; border-color: #d43f3a; }

.btn-danger:focus, .btn-danger.focus { color: #fff; background-color: #c9302c; border-color: #761c19; }

.btn-danger:hover { color: #fff; background-color: #c9302c; border-color: #ac2925; }

.btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle { color: #fff; background-color: #c9302c; border-color: #ac2925; }

.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus, .open > .btn-danger.dropdown-toggle:hover, .open > .btn-danger.dropdown-toggle:focus, .open > .btn-danger.dropdown-toggle.focus { color: #fff; background-color: #ac2925; border-color: #761c19; }

.btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle { background-image: none; }

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled].active, fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger.focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger.active { background-color: #d9534f; border-color: #d43f3a; }

.btn-danger .badge { color: #d9534f; background-color: #fff; }

.btn-link { color: #005593; font-weight: normal; border-radius: 0; }

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link { background-color: transparent; box-shadow: none; }

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active { border-color: transparent; }

.btn-link:hover, .btn-link:focus { color: #002946; text-decoration: none; background-color: transparent; }

.btn-link[disabled]:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus { color: #c7c7c7; text-decoration: none; }

.btn-lg, .btn-group-lg > .btn { padding: 10px 16px; font-size: 22px; line-height: 1.3333333; border-radius: 0; }

.btn-sm, .btn-group-sm > .btn { padding: 5px 10px; font-size: 15px; line-height: 1.5; border-radius: 0; }

.btn-xs, .btn-group-xs > .btn { padding: 1px 5px; font-size: 15px; line-height: 1.5; border-radius: 0; }

.btn-block { display: block; width: 100%; }

.btn-block + .btn-block { margin-top: 5px; }

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block { width: 100%; }

.fade { opacity: 0.01; -webkit-transition: opacity 0.15s linear; -o-transition: opacity 0.15s linear; transition: opacity 0.15s linear; }

.fade.in { opacity: 1; }

.collapse { display: none; }

.collapse.in { display: block; }

tr.collapse.in { display: table-row; }

tbody.collapse.in { display: table-row-group; }

.collapsing { position: relative; height: 0; overflow: hidden; -webkit-transition-property: height, visibility; transition-property: height, visibility; -webkit-transition-duration: 0.35s; transition-duration: 0.35s; -webkit-transition-timing-function: ease; transition-timing-function: ease; }

.caret { display: inline-block; width: 0; height: 0; margin-left: 2px; vertical-align: middle; border-top: 4px dashed; border-top: 4px solid \9; border-right: 4px solid transparent; border-left: 4px solid transparent; }

.dropup, .dropdown { position: relative; }

.dropdown-toggle:focus { outline: 0; }

.dropdown-menu { position: absolute; top: 100%; left: 0; z-index: 1000; display: none; float: left; min-width: 160px; padding: 5px 0; margin: 2px 0 0; list-style: none; font-size: 17px; text-align: left; background-color: #fff; border: 1px solid #ccc; border: 1px solid rgba(0, 0, 0, 0.15); border-radius: 4px; box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); background-clip: padding-box; }

.dropdown-menu.pull-right { right: 0; left: auto; }

.dropdown-menu .divider { height: 1px; margin: 13px 0; overflow: hidden; background-color: #e5e5e5; }

.dropdown-menu > li > a { display: block; padding: 3px 20px; clear: both; font-weight: normal; line-height: 1.6666666667; color: #333333; white-space: nowrap; }

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus { text-decoration: none; color: #262626; background-color: #f5f5f5; }

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus { color: #fff; text-decoration: none; outline: 0; background-color: #005593; }

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus { color: #777777; }

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus { text-decoration: none; background-color: transparent; background-image: none; filter: progid:DXImageTransform.Microsoft.gradient(enabled = false); cursor: not-allowed; }

.open > .dropdown-menu { display: block; }

.open > a { outline: 0; }

.dropdown-menu-right { left: auto; right: 0; }

.dropdown-menu-left { left: 0; right: auto; }

.dropdown-header { display: block; padding: 3px 20px; font-size: 15px; line-height: 1.6666666667; color: #777777; white-space: nowrap; }

.dropdown-backdrop { position: fixed; left: 0; right: 0; bottom: 0; top: 0; z-index: 990; }

.pull-right > .dropdown-menu { right: 0; left: auto; }

.dropup .caret, .navbar-fixed-bottom .dropdown .caret { border-top: 0; border-bottom: 4px dashed; border-bottom: 4px solid \9; content: ""; }

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu { top: auto; bottom: 100%; margin-bottom: 2px; }

@media (min-width: 768px) { .navbar-right .dropdown-menu { right: 0; left: auto; }
  .navbar-right .dropdown-menu-left { left: 0; right: auto; } }

.btn-group, .btn-group-vertical { position: relative; display: inline-block; vertical-align: middle; }

.btn-group > .btn, .btn-group-vertical > .btn { position: relative; float: left; }

.btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active { z-index: 2; }

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group { margin-left: -1px; }

.btn-toolbar { margin-left: -5px; }

.btn-toolbar:before, .btn-toolbar:after { content: " "; display: table; }

.btn-toolbar:after { clear: both; }

.btn-toolbar .btn, .btn-toolbar .btn-group, .btn-toolbar .input-group { float: left; }

.btn-toolbar > .btn, .btn-toolbar > .btn-group, .btn-toolbar > .input-group { margin-left: 5px; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) { border-radius: 0; }

.btn-group > .btn:first-child { margin-left: 0; }

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) { border-bottom-right-radius: 0; border-top-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) { border-bottom-left-radius: 0; border-top-left-radius: 0; }

.btn-group > .btn-group { float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn { border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle { border-bottom-right-radius: 0; border-top-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child { border-bottom-left-radius: 0; border-top-left-radius: 0; }

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle { outline: 0; }

.btn-group > .btn + .dropdown-toggle { padding-left: 8px; padding-right: 8px; }

.btn-group > .btn-lg + .dropdown-toggle, .btn-group-lg.btn-group > .btn + .dropdown-toggle { padding-left: 12px; padding-right: 12px; }

.btn-group.open .dropdown-toggle { box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-group.open .dropdown-toggle.btn-link { box-shadow: none; }

.btn .caret { margin-left: 0; }

.btn-lg .caret, .btn-group-lg > .btn .caret { border-width: 5px 5px 0; border-bottom-width: 0; }

.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret { border-width: 0 5px 5px; }

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group, .btn-group-vertical > .btn-group > .btn { display: block; float: none; width: 100%; max-width: 100%; }

.btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after { content: " "; display: table; }

.btn-group-vertical > .btn-group:after { clear: both; }

.btn-group-vertical > .btn-group > .btn { float: none; }

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group { margin-top: -1px; margin-left: 0; }

.btn-group-vertical > .btn:not(:first-child):not(:last-child) { border-radius: 0; }

.btn-group-vertical > .btn:first-child:not(:last-child) { border-top-right-radius: 0; border-bottom-right-radius: 0; border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:last-child:not(:first-child) { border-bottom-left-radius: 0; border-top-right-radius: 0; border-top-left-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn { border-radius: 0; }

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle { border-bottom-right-radius: 0; border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child { border-top-right-radius: 0; border-top-left-radius: 0; }

.btn-group-justified { display: table; width: 100%; table-layout: fixed; border-collapse: separate; }

.btn-group-justified > .btn, .btn-group-justified > .btn-group { float: none; display: table-cell; width: 1%; }

.btn-group-justified > .btn-group .btn { width: 100%; }

.btn-group-justified > .btn-group .dropdown-menu { left: auto; }

[data-toggle="buttons"] > .btn input[type="radio"], [data-toggle="buttons"] > .btn input[type="checkbox"], [data-toggle="buttons"] > .btn-group > .btn input[type="radio"], [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] { position: absolute; clip: rect(0, 0, 0, 0); pointer-events: none; }

.nav { margin-bottom: 0; padding-left: 0; list-style: none; }

.nav:before, .nav:after { content: " "; display: table; }

.nav:after { clear: both; }

.nav > li { position: relative; display: block; }

.nav > li > a { position: relative; display: block; padding: 10px 15px; }

.nav > li > a:hover, .nav > li > a:focus { text-decoration: none; background-color: #eeeeee; }

.nav > li.disabled > a { color: #777777; }

.nav > li.disabled > a:hover, .nav > li.disabled > a:focus { color: #777777; text-decoration: none; background-color: transparent; cursor: not-allowed; }

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus { background-color: #eeeeee; border-color: #005593; }

.nav .nav-divider { height: 1px; margin: 13px 0; overflow: hidden; background-color: #e5e5e5; }

.nav > li > a > img { max-width: none; }

.nav-tabs { border-bottom: 1px solid #ddd; }

.nav-tabs > li { float: left; margin-bottom: -1px; }

.nav-tabs > li > a { margin-right: 2px; line-height: 1.6666666667; border: 1px solid transparent; border-radius: 4px 4px 0 0; }

.nav-tabs > li > a:hover { border-color: #eeeeee #eeeeee #ddd; }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus { color: #555555; background-color: #f2ede9; border: 1px solid #ddd; border-bottom-color: transparent; cursor: default; }

.nav-pills > li { float: left; }

.nav-pills > li > a { border-radius: 4px; }

.nav-pills > li + li { margin-left: 2px; }

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus { color: #fff; background-color: #005593; }

.nav-stacked > li { float: none; }

.nav-stacked > li + li { margin-top: 2px; margin-left: 0; }

.nav-justified, .nav-tabs.nav-justified { width: 100%; }

.nav-justified > li, .nav-tabs.nav-justified > li { float: none; }

.nav-justified > li > a, .nav-tabs.nav-justified > li > a { text-align: center; margin-bottom: 5px; }

.nav-justified > .dropdown .dropdown-menu { top: auto; left: auto; }

@media (min-width: 768px) { .nav-justified > li, .nav-tabs.nav-justified > li { display: table-cell; width: 1%; }
  .nav-justified > li > a, .nav-tabs.nav-justified > li > a { margin-bottom: 0; } }

.nav-tabs-justified, .nav-tabs.nav-justified { border-bottom: 0; }

.nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a { margin-right: 0; border-radius: 4px; }

.nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:focus { border: 1px solid #ddd; }

@media (min-width: 768px) { .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a { border-bottom: 1px solid #ddd; border-radius: 4px 4px 0 0; }
  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:focus { border-bottom-color: #f2ede9; } }

.tab-content > .tab-pane { display: none; }

.tab-content > .active { display: block; }

.nav-tabs .dropdown-menu { margin-top: -1px; border-top-right-radius: 0; border-top-left-radius: 0; }

.pagination { display: inline-block; padding-left: 0; margin: 28px 0; border-radius: 4px; }

.pagination > li { display: inline; }

.pagination > li > a, .pagination > li > span { position: relative; float: left; padding: 6px 12px; line-height: 1.6666666667; text-decoration: none; color: #005593; background-color: #fff; border: 1px solid #ddd; margin-left: -1px; }

.pagination > li:first-child > a, .pagination > li:first-child > span { margin-left: 0; border-bottom-left-radius: 4px; border-top-left-radius: 4px; }

.pagination > li:last-child > a, .pagination > li:last-child > span { border-bottom-right-radius: 4px; border-top-right-radius: 4px; }

.pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus { z-index: 3; color: #002946; background-color: #eeeeee; border-color: #ddd; }

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus { z-index: 2; color: #fff; background-color: #005593; border-color: #005593; cursor: default; }

.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus { color: #777777; background-color: #fff; border-color: #ddd; cursor: not-allowed; }

.pagination-lg > li > a, .pagination-lg > li > span { padding: 10px 16px; font-size: 22px; line-height: 1.3333333; }

.pagination-lg > li:first-child > a, .pagination-lg > li:first-child > span { border-bottom-left-radius: 6px; border-top-left-radius: 6px; }

.pagination-lg > li:last-child > a, .pagination-lg > li:last-child > span { border-bottom-right-radius: 6px; border-top-right-radius: 6px; }

.pagination-sm > li > a, .pagination-sm > li > span { padding: 5px 10px; font-size: 15px; line-height: 1.5; }

.pagination-sm > li:first-child > a, .pagination-sm > li:first-child > span { border-bottom-left-radius: 3px; border-top-left-radius: 3px; }

.pagination-sm > li:last-child > a, .pagination-sm > li:last-child > span { border-bottom-right-radius: 3px; border-top-right-radius: 3px; }

.label { display: inline; padding: .2em .6em .3em; font-size: 75%; font-weight: bold; line-height: 1; color: #fff; text-align: center; white-space: nowrap; vertical-align: baseline; border-radius: .25em; }

.label:empty { display: none; }

.btn .label { position: relative; top: -1px; }

a.label:hover, a.label:focus { color: #fff; text-decoration: none; cursor: pointer; }

.label-default { background-color: #777777; }

.label-default[href]:hover, .label-default[href]:focus { background-color: #5e5e5e; }

.label-primary { background-color: #005593; }

.label-primary[href]:hover, .label-primary[href]:focus { background-color: #003760; }

.label-success { background-color: #5cb85c; }

.label-success[href]:hover, .label-success[href]:focus { background-color: #449d44; }

.label-info { background-color: #5bc0de; }

.label-info[href]:hover, .label-info[href]:focus { background-color: #31b0d5; }

.label-warning { background-color: #f0ad4e; }

.label-warning[href]:hover, .label-warning[href]:focus { background-color: #ec971f; }

.label-danger { background-color: #d9534f; }

.label-danger[href]:hover, .label-danger[href]:focus { background-color: #c9302c; }

.badge { display: inline-block; min-width: 10px; padding: 3px 7px; font-size: 15px; font-weight: bold; color: #fff; line-height: 1; vertical-align: middle; white-space: nowrap; text-align: center; background-color: #777777; border-radius: 10px; }

.badge:empty { display: none; }

.btn .badge { position: relative; top: -1px; }

.btn-xs .badge, .btn-group-xs > .btn .badge, .btn-group-xs > .btn .badge { top: 0; padding: 1px 5px; }

.list-group-item.active > .badge, .nav-pills > .active > a > .badge { color: #005593; background-color: #fff; }

.list-group-item > .badge { float: right; }

.list-group-item > .badge + .badge { margin-right: 5px; }

.nav-pills > li > a > .badge { margin-left: 3px; }

a.badge:hover, a.badge:focus { color: #fff; text-decoration: none; cursor: pointer; }

.alert { padding: 15px; margin-bottom: 28px; border: 1px solid transparent; border-radius: 4px; }

.alert h4 { margin-top: 0; color: inherit; }

.alert .alert-link { font-weight: bold; }

.alert > p, .alert > ul { margin-bottom: 0; }

.alert > p + p { margin-top: 5px; }

.alert-dismissable, .alert-dismissible { padding-right: 35px; }

.alert-dismissable .close, .alert-dismissible .close { position: relative; top: -2px; right: -21px; color: inherit; }

.alert-success { background-color: #dff0d8; border-color: #d6e9c6; color: #3c763d; }

.alert-success hr { border-top-color: #c9e2b3; }

.alert-success .alert-link { color: #2b542c; }

.alert-info { background-color: #d9edf7; border-color: #bce8f1; color: #31708f; }

.alert-info hr { border-top-color: #a6e1ec; }

.alert-info .alert-link { color: #245269; }

.alert-warning { background-color: #fcf8e3; border-color: #faebcc; color: #8a6d3b; }

.alert-warning hr { border-top-color: #f7e1b5; }

.alert-warning .alert-link { color: #66512c; }

.alert-danger, .alert-error { background-color: #f2dede; border-color: #ebccd1; color: #a94442; }

.alert-danger hr, .alert-error hr { border-top-color: #e4b9c0; }

.alert-danger .alert-link, .alert-error .alert-link { color: #843534; }

.panel { margin-bottom: 28px; background-color: #fff; border: 1px solid transparent; border-radius: 4px; box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.panel-body { padding: 15px; }

.panel-body:before, .panel-body:after { content: " "; display: table; }

.panel-body:after { clear: both; }

.panel-heading { padding: 10px 15px; border-bottom: 1px solid transparent; border-top-right-radius: 3px; border-top-left-radius: 3px; }

.panel-heading > .dropdown .dropdown-toggle { color: inherit; }

.panel-title { margin-top: 0; margin-bottom: 0; font-size: 20px; color: inherit; }

.panel-title > a, .panel-title > small, .panel-title > .small, .panel-title > small > a, .panel-title > .small > a { color: inherit; }

.panel-footer { padding: 10px 15px; background-color: #f5f5f5; border-top: 1px solid #ddd; border-bottom-right-radius: 3px; border-bottom-left-radius: 3px; }

.panel > .list-group, .panel > .panel-collapse > .list-group { margin-bottom: 0; }

.panel > .list-group .list-group-item, .panel > .panel-collapse > .list-group .list-group-item { border-width: 1px 0; border-radius: 0; }

.panel > .list-group:first-child .list-group-item:first-child, .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child { border-top: 0; border-top-right-radius: 3px; border-top-left-radius: 3px; }

.panel > .list-group:last-child .list-group-item:last-child, .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child { border-bottom: 0; border-bottom-right-radius: 3px; border-bottom-left-radius: 3px; }

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child { border-top-right-radius: 0; border-top-left-radius: 0; }

.panel-heading + .list-group .list-group-item:first-child { border-top-width: 0; }

.list-group + .panel-footer { border-top-width: 0; }

.panel > .table, .panel > .table-responsive > .table, .panel > .panel-collapse > .table { margin-bottom: 0; }

.panel > .table caption, .panel > .table-responsive > .table caption, .panel > .panel-collapse > .table caption { padding-left: 15px; padding-right: 15px; }

.panel > .table:first-child, .panel > .table-responsive:first-child > .table:first-child { border-top-right-radius: 3px; border-top-left-radius: 3px; }

.panel > .table:first-child > thead:first-child > tr:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child { border-top-left-radius: 3px; border-top-right-radius: 3px; }

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child { border-top-left-radius: 3px; }

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child { border-top-right-radius: 3px; }

.panel > .table:last-child, .panel > .table-responsive:last-child > .table:last-child { border-bottom-right-radius: 3px; border-bottom-left-radius: 3px; }

.panel > .table:last-child > tbody:last-child > tr:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child { border-bottom-left-radius: 3px; border-bottom-right-radius: 3px; }

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child { border-bottom-left-radius: 3px; }

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child { border-bottom-right-radius: 3px; }

.panel > .panel-body + .table, .panel > .panel-body + .table-responsive, .panel > .table + .panel-body, .panel > .table-responsive + .panel-body { border-top: 1px solid #ddd; }

.panel > .table > tbody:first-child > tr:first-child th, .panel > .table > tbody:first-child > tr:first-child td { border-top: 0; }

.panel > .table-bordered, .panel > .table-responsive > .table-bordered { border: 0; }

.panel > .table-bordered > thead > tr > th:first-child, .panel > .table-bordered > thead > tr > td:first-child, .panel > .table-bordered > tbody > tr > th:first-child, .panel > .table-bordered > tbody > tr > td:first-child, .panel > .table-bordered > tfoot > tr > th:first-child, .panel > .table-bordered > tfoot > tr > td:first-child, .panel > .table-responsive > .table-bordered > thead > tr > th:first-child, .panel > .table-responsive > .table-bordered > thead > tr > td:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child { border-left: 0; }

.panel > .table-bordered > thead > tr > th:last-child, .panel > .table-bordered > thead > tr > td:last-child, .panel > .table-bordered > tbody > tr > th:last-child, .panel > .table-bordered > tbody > tr > td:last-child, .panel > .table-bordered > tfoot > tr > th:last-child, .panel > .table-bordered > tfoot > tr > td:last-child, .panel > .table-responsive > .table-bordered > thead > tr > th:last-child, .panel > .table-responsive > .table-bordered > thead > tr > td:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child { border-right: 0; }

.panel > .table-bordered > thead > tr:first-child > td, .panel > .table-bordered > thead > tr:first-child > th, .panel > .table-bordered > tbody > tr:first-child > td, .panel > .table-bordered > tbody > tr:first-child > th, .panel > .table-responsive > .table-bordered > thead > tr:first-child > td, .panel > .table-responsive > .table-bordered > thead > tr:first-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th { border-bottom: 0; }

.panel > .table-bordered > tbody > tr:last-child > td, .panel > .table-bordered > tbody > tr:last-child > th, .panel > .table-bordered > tfoot > tr:last-child > td, .panel > .table-bordered > tfoot > tr:last-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th { border-bottom: 0; }

.panel > .table-responsive { border: 0; margin-bottom: 0; }

.panel-group { margin-bottom: 28px; }

.panel-group .panel { margin-bottom: 0; border-radius: 4px; }

.panel-group .panel + .panel { margin-top: 5px; }

.panel-group .panel-heading { border-bottom: 0; }

.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group { border-top: 1px solid #ddd; }

.panel-group .panel-footer { border-top: 0; }

.panel-group .panel-footer + .panel-collapse .panel-body { border-bottom: 1px solid #ddd; }

.panel-default { border-color: #ddd; }

.panel-default > .panel-heading { color: #333333; background-color: #f5f5f5; border-color: #ddd; }

.panel-default > .panel-heading + .panel-collapse > .panel-body { border-top-color: #ddd; }

.panel-default > .panel-heading .badge { color: #f5f5f5; background-color: #333333; }

.panel-default > .panel-footer + .panel-collapse > .panel-body { border-bottom-color: #ddd; }

.panel-primary { border-color: #005593; }

.panel-primary > .panel-heading { color: #fff; background-color: #005593; border-color: #005593; }

.panel-primary > .panel-heading + .panel-collapse > .panel-body { border-top-color: #005593; }

.panel-primary > .panel-heading .badge { color: #005593; background-color: #fff; }

.panel-primary > .panel-footer + .panel-collapse > .panel-body { border-bottom-color: #005593; }

.panel-success { border-color: #d6e9c6; }

.panel-success > .panel-heading { color: #3c763d; background-color: #dff0d8; border-color: #d6e9c6; }

.panel-success > .panel-heading + .panel-collapse > .panel-body { border-top-color: #d6e9c6; }

.panel-success > .panel-heading .badge { color: #dff0d8; background-color: #3c763d; }

.panel-success > .panel-footer + .panel-collapse > .panel-body { border-bottom-color: #d6e9c6; }

.panel-info { border-color: #bce8f1; }

.panel-info > .panel-heading { color: #31708f; background-color: #d9edf7; border-color: #bce8f1; }

.panel-info > .panel-heading + .panel-collapse > .panel-body { border-top-color: #bce8f1; }

.panel-info > .panel-heading .badge { color: #d9edf7; background-color: #31708f; }

.panel-info > .panel-footer + .panel-collapse > .panel-body { border-bottom-color: #bce8f1; }

.panel-warning { border-color: #faebcc; }

.panel-warning > .panel-heading { color: #8a6d3b; background-color: #fcf8e3; border-color: #faebcc; }

.panel-warning > .panel-heading + .panel-collapse > .panel-body { border-top-color: #faebcc; }

.panel-warning > .panel-heading .badge { color: #fcf8e3; background-color: #8a6d3b; }

.panel-warning > .panel-footer + .panel-collapse > .panel-body { border-bottom-color: #faebcc; }

.panel-danger { border-color: #ebccd1; }

.panel-danger > .panel-heading { color: #a94442; background-color: #f2dede; border-color: #ebccd1; }

.panel-danger > .panel-heading + .panel-collapse > .panel-body { border-top-color: #ebccd1; }

.panel-danger > .panel-heading .badge { color: #f2dede; background-color: #a94442; }

.panel-danger > .panel-footer + .panel-collapse > .panel-body { border-bottom-color: #ebccd1; }

.embed-responsive { position: relative; display: block; height: 0; padding: 0; overflow: hidden; }

.embed-responsive .embed-responsive-item, .embed-responsive img, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video { position: absolute; top: 0; left: 0; bottom: 0; height: 100%; width: 100%; border: 0; }

.embed-responsive-16by9 { padding-bottom: 56.25%; }

.embed-responsive-4by3 { padding-bottom: 75%; }

.close { float: right; font-size: 25.5px; font-weight: bold; line-height: 1; color: #000; text-shadow: 0 1px 0 #fff; opacity: 0.2; }

.close:hover, .close:focus { color: #000; text-decoration: none; cursor: pointer; opacity: 0.5; }

button.close { padding: 0; cursor: pointer; background: transparent; border: 0; -webkit-appearance: none; }

.modal-open { overflow: hidden; }

.modal { display: none; overflow: hidden; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1050; -webkit-overflow-scrolling: touch; outline: 0; }

.modal.fade .modal-dialog { -webkit-transform: translate(0, -25%); -ms-transform: translate(0, -25%); -o-transform: translate(0, -25%); transform: translate(0, -25%); -webkit-transition: -webkit-transform 0.3s ease-out; -moz-transition: -moz-transform 0.3s ease-out; -o-transition: -o-transform 0.3s ease-out; transition: transform 0.3s ease-out; }

.modal.in .modal-dialog { -webkit-transform: translate(0, 0); -ms-transform: translate(0, 0); -o-transform: translate(0, 0); transform: translate(0, 0); }

.modal-open .modal { overflow-x: hidden; overflow-y: auto; }

.modal-dialog { position: relative; width: auto; margin: 10px; }

.modal-content { position: relative; background-color: #fff; border: 1px solid #999; border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 6px; box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); background-clip: padding-box; outline: 0; }

.modal-backdrop { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1040; background-color: #000; }

.modal-backdrop.fade { opacity: 0; }

.modal-backdrop.in { opacity: 0.5; }

.modal-header { padding: 15px; border-bottom: 1px solid #e5e5e5; min-height: 16.6666666667px; }

.modal-header .close { margin-top: -2px; }

.modal-title { margin: 0; line-height: 1.6666666667; }

.modal-body { position: relative; padding: 15px; }

.modal-footer { padding: 15px; text-align: right; border-top: 1px solid #e5e5e5; }

.modal-footer:before, .modal-footer:after { content: " "; display: table; }

.modal-footer:after { clear: both; }

.modal-footer .btn + .btn { margin-left: 5px; margin-bottom: 0; }

.modal-footer .btn-group .btn + .btn { margin-left: -1px; }

.modal-footer .btn-block + .btn-block { margin-left: 0; }

.modal-scrollbar-measure { position: absolute; top: -9999px; width: 50px; height: 50px; overflow: scroll; }

@media (min-width: 768px) { .modal-dialog { width: 600px; margin: 30px auto; }
  .modal-content { box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm { width: 300px; } }

@media (min-width: 992px) { .modal-lg { width: 900px; } }

.tooltip { position: absolute; z-index: 1070; display: block; font-family: 'Cambria', serif; font-style: normal; font-weight: normal; letter-spacing: normal; line-break: auto; line-height: 1.6666666667; text-align: left; text-align: start; text-decoration: none; text-shadow: none; text-transform: none; white-space: normal; word-break: normal; word-spacing: normal; word-wrap: normal; font-size: 15px; opacity: 0; }

.tooltip.in { opacity: 0.9; }

.tooltip.top { margin-top: -3px; padding: 5px 0; }

.tooltip.right { margin-left: 3px; padding: 0 5px; }

.tooltip.bottom { margin-top: 3px; padding: 5px 0; }

.tooltip.left { margin-left: -3px; padding: 0 5px; }

.tooltip-inner { max-width: 200px; padding: 3px 8px; color: #fff; text-align: center; background-color: #000; border-radius: 4px; }

.tooltip-arrow { position: absolute; width: 0; height: 0; border-color: transparent; border-style: solid; }

.tooltip.top .tooltip-arrow { bottom: 0; left: 50%; margin-left: -5px; border-width: 5px 5px 0; border-top-color: #000; }

.tooltip.top-left .tooltip-arrow { bottom: 0; right: 5px; margin-bottom: -5px; border-width: 5px 5px 0; border-top-color: #000; }

.tooltip.top-right .tooltip-arrow { bottom: 0; left: 5px; margin-bottom: -5px; border-width: 5px 5px 0; border-top-color: #000; }

.tooltip.right .tooltip-arrow { top: 50%; left: 0; margin-top: -5px; border-width: 5px 5px 5px 0; border-right-color: #000; }

.tooltip.left .tooltip-arrow { top: 50%; right: 0; margin-top: -5px; border-width: 5px 0 5px 5px; border-left-color: #000; }

.tooltip.bottom .tooltip-arrow { top: 0; left: 50%; margin-left: -5px; border-width: 0 5px 5px; border-bottom-color: #000; }

.tooltip.bottom-left .tooltip-arrow { top: 0; right: 5px; margin-top: -5px; border-width: 0 5px 5px; border-bottom-color: #000; }

.tooltip.bottom-right .tooltip-arrow { top: 0; left: 5px; margin-top: -5px; border-width: 0 5px 5px; border-bottom-color: #000; }

.popover { position: absolute; top: 0; left: 0; z-index: 1060; display: none; max-width: 276px; padding: 1px; font-family: 'Cambria', serif; font-style: normal; font-weight: normal; letter-spacing: normal; line-break: auto; line-height: 1.6666666667; text-align: left; text-align: start; text-decoration: none; text-shadow: none; text-transform: none; white-space: normal; word-break: normal; word-spacing: normal; word-wrap: normal; font-size: 17px; background-color: #fff; background-clip: padding-box; border: 1px solid #ccc; border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 6px; box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }

.popover.top { margin-top: -10px; }

.popover.right { margin-left: 10px; }

.popover.bottom { margin-top: 10px; }

.popover.left { margin-left: -10px; }

.popover-title { margin: 0; padding: 8px 14px; font-size: 17px; background-color: #f7f7f7; border-bottom: 1px solid #ebebeb; border-radius: 5px 5px 0 0; }

.popover-content { padding: 9px 14px; }

.popover > .arrow, .popover > .arrow:after { position: absolute; display: block; width: 0; height: 0; border-color: transparent; border-style: solid; }

.popover > .arrow { border-width: 11px; }

.popover > .arrow:after { border-width: 10px; content: ""; }

.popover.top > .arrow { left: 50%; margin-left: -11px; border-bottom-width: 0; border-top-color: #999999; border-top-color: rgba(0, 0, 0, 0.25); bottom: -11px; }

.popover.top > .arrow:after { content: " "; bottom: 1px; margin-left: -10px; border-bottom-width: 0; border-top-color: #fff; }

.popover.right > .arrow { top: 50%; left: -11px; margin-top: -11px; border-left-width: 0; border-right-color: #999999; border-right-color: rgba(0, 0, 0, 0.25); }

.popover.right > .arrow:after { content: " "; left: 1px; bottom: -10px; border-left-width: 0; border-right-color: #fff; }

.popover.bottom > .arrow { left: 50%; margin-left: -11px; border-top-width: 0; border-bottom-color: #999999; border-bottom-color: rgba(0, 0, 0, 0.25); top: -11px; }

.popover.bottom > .arrow:after { content: " "; top: 1px; margin-left: -10px; border-top-width: 0; border-bottom-color: #fff; }

.popover.left > .arrow { top: 50%; right: -11px; margin-top: -11px; border-right-width: 0; border-left-color: #999999; border-left-color: rgba(0, 0, 0, 0.25); }

.popover.left > .arrow:after { content: " "; right: 1px; border-right-width: 0; border-left-color: #fff; bottom: -10px; }

.clearfix:before, .clearfix:after { content: " "; display: table; }

.clearfix:after { clear: both; }

.center-block { display: block; margin-left: auto; margin-right: auto; }

.pull-right { float: right !important; }

.pull-left { float: left !important; }

.hide { display: none !important; }

.show { display: block !important; }

.invisible { visibility: hidden; }

.text-hide { font: 0/0 a; color: transparent; text-shadow: none; background-color: transparent; border: 0; }

.hidden { display: none !important; }

.affix { position: fixed; }

@-ms-viewport { width: device-width; }

.visible-xs { display: none !important; }

.visible-sm { display: none !important; }

.visible-md { display: none !important; }

.visible-lg { display: none !important; }

.visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block { display: none !important; }

@media (max-width: 767px) { .visible-xs { display: block !important; }
  table.visible-xs { display: table !important; }
  tr.visible-xs { display: table-row !important; }
  th.visible-xs, td.visible-xs { display: table-cell !important; } }

@media (max-width: 767px) { .visible-xs-block { display: block !important; } }

@media (max-width: 767px) { .visible-xs-inline { display: inline !important; } }

@media (max-width: 767px) { .visible-xs-inline-block { display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) { .visible-sm { display: block !important; }
  table.visible-sm { display: table !important; }
  tr.visible-sm { display: table-row !important; }
  th.visible-sm, td.visible-sm { display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) { .visible-sm-block { display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) { .visible-sm-inline { display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) { .visible-sm-inline-block { display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) { .visible-md { display: block !important; }
  table.visible-md { display: table !important; }
  tr.visible-md { display: table-row !important; }
  th.visible-md, td.visible-md { display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) { .visible-md-block { display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) { .visible-md-inline { display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) { .visible-md-inline-block { display: inline-block !important; } }

@media (min-width: 1200px) { .visible-lg { display: block !important; }
  table.visible-lg { display: table !important; }
  tr.visible-lg { display: table-row !important; }
  th.visible-lg, td.visible-lg { display: table-cell !important; } }

@media (min-width: 1200px) { .visible-lg-block { display: block !important; } }

@media (min-width: 1200px) { .visible-lg-inline { display: inline !important; } }

@media (min-width: 1200px) { .visible-lg-inline-block { display: inline-block !important; } }

@media (max-width: 767px) { .hidden-xs { display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) { .hidden-sm { display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) { .hidden-md { display: none !important; } }

@media (min-width: 1200px) { .hidden-lg { display: none !important; } }

.visible-print { display: none !important; }

@media print { .visible-print { display: block !important; }
  table.visible-print { display: table !important; }
  tr.visible-print { display: table-row !important; }
  th.visible-print, td.visible-print { display: table-cell !important; } }

.visible-print-block { display: none !important; }

@media print { .visible-print-block { display: block !important; } }

.visible-print-inline { display: none !important; }

@media print { .visible-print-inline { display: inline !important; } }

.visible-print-inline-block { display: none !important; }

@media print { .visible-print-inline-block { display: inline-block !important; } }

@media print { .hidden-print { display: none !important; } }

/*  Created on : 28-ott-2015, 17.18.36 Author     : Davide */
/*@mixin button-variant($color, $background, $border) { color: $color; background-color: $background; border-color: $border; &:hover, &:focus, &.focus, &:active, &.active, .open > &.dropdown-toggle { color: $color; background-color: darken($background, 10%); border-color: darken($border, 12%); } &:active, &.active, .open > &.dropdown-toggle { background-image: none; } &.disabled, &[disabled], fieldset[disabled] & { &, &:hover, &:focus, &.focus, &:active, &.active { background-color: $background; border-color: $border; } } .badge { color: $background; background-color: $color; } }*/
.col, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 { position: relative; min-height: 1px; padding-left: 19px; padding-right: 19px; }

.breadcrumb { font-size: 0.875em; padding: 0.4705882353em 0; margin-top: 1.6470588235em; margin-bottom: 0; list-style: none; background-color: transparent; border-radius: 4px; }

.breadcrumb > li { display: inline-block; }

.breadcrumb > li + li:before { content: "/ "; padding: 0 5px; color: #ccc; }

.breadcrumb > .active { color: #777777; }

label { width: stretch; }

.btn { font-family: 'Oxygen', sans-serif; text-transform: uppercase; transition: 0.25s; transition-property: color, background, border; }

.btn-flex { padding: 0.3529411765em 1.4117647059em; font-size: inherit; line-height: 1.6666666667; border-radius: 0; }

.input-group { position: relative; display: table; border-collapse: separate; }

.input-group[class*="col-"] { float: none; padding-left: 0; padding-right: 0; }

.input-group .form-control { position: relative; z-index: 2; float: left; width: 100%; margin-bottom: 0; }

.input-group-addon, .input-group-btn, .input-group .form-control { display: table-cell; }

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) { border-radius: 0; }

.input-group-addon, .input-group-btn { width: 1%; white-space: nowrap; vertical-align: middle; }

.input-group-addon { padding: 6px 12px; font-size: 17px; font-weight: normal; line-height: 1; color: #555555; text-align: center; background-color: #fafafa; border: 1px solid #e6e5e5; border-radius: input-group-border-radius; }

.input-group-addon.input-sm, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .input-group-addon.btn { padding: 5px 10px; font-size: 15px; border-radius: 0; }

.input-group-addon.input-lg, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .input-group-addon.btn { padding: 10px 16px; font-size: 22px; border-radius: 0; }

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] { margin-top: 0; }

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child > .btn-group:not(:last-child) > .btn { border-bottom-right-radius: 0; border-top-right-radius: 0; }

.input-group-addon:first-child { border-right: 0; }

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn { border-bottom-left-radius: 0; border-top-left-radius: 0; }

.input-group-addon:last-child { border-left: 0; }

.input-group-btn { position: relative; font-size: 0; white-space: nowrap; }

.input-group-btn > .btn { position: relative; }

.input-group-btn > .btn + .btn { margin-left: -1px; }

.input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active { z-index: 2; }

.input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group { margin-right: -1px; }

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group { z-index: 2; margin-left: -1px; }

.container { margin-right: auto; margin-left: auto; padding-left: 19px; padding-right: 19px; width: 758px; }

@media (min-width: 992px) { .container { width: 978px; } }

@media (min-width: 1200px) { .container { width: 1178px; } }

.container.max-sm { max-width: 758px !important; }

.container.max-md { max-width: 978px !important; }

.container.max-lg { max-width: 1178px !important; }

.container.max-xl { max-width: 1318px !important; }

.container-fluid { margin-right: auto; margin-left: auto; padding-left: 19px; padding-right: 19px; }

.row { margin-left: -19px; margin-right: -19px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 { float: left; }

.col-xs-1 { width: 8.3333333333%; }

.col-xs-2 { width: 16.6666666667%; }

.col-xs-3 { width: 25%; }

.col-xs-4 { width: 33.3333333333%; }

.col-xs-5 { width: 41.6666666667%; }

.col-xs-6 { width: 50%; }

.col-xs-7 { width: 58.3333333333%; }

.col-xs-8 { width: 66.6666666667%; }

.col-xs-9 { width: 75%; }

.col-xs-10 { width: 83.3333333333%; }

.col-xs-11 { width: 91.6666666667%; }

.col-xs-12 { width: 100%; }

.col-xs-pull-0 { right: auto; }

.col-xs-pull-1 { right: 8.3333333333%; }

.col-xs-pull-2 { right: 16.6666666667%; }

.col-xs-pull-3 { right: 25%; }

.col-xs-pull-4 { right: 33.3333333333%; }

.col-xs-pull-5 { right: 41.6666666667%; }

.col-xs-pull-6 { right: 50%; }

.col-xs-pull-7 { right: 58.3333333333%; }

.col-xs-pull-8 { right: 66.6666666667%; }

.col-xs-pull-9 { right: 75%; }

.col-xs-pull-10 { right: 83.3333333333%; }

.col-xs-pull-11 { right: 91.6666666667%; }

.col-xs-pull-12 { right: 100%; }

.col-xs-push-0 { left: auto; }

.col-xs-push-1 { left: 8.3333333333%; }

.col-xs-push-2 { left: 16.6666666667%; }

.col-xs-push-3 { left: 25%; }

.col-xs-push-4 { left: 33.3333333333%; }

.col-xs-push-5 { left: 41.6666666667%; }

.col-xs-push-6 { left: 50%; }

.col-xs-push-7 { left: 58.3333333333%; }

.col-xs-push-8 { left: 66.6666666667%; }

.col-xs-push-9 { left: 75%; }

.col-xs-push-10 { left: 83.3333333333%; }

.col-xs-push-11 { left: 91.6666666667%; }

.col-xs-push-12 { left: 100%; }

.col-xs-offset-0 { margin-left: 0%; }

.col-xs-offset-1 { margin-left: 8.3333333333%; }

.col-xs-offset-2 { margin-left: 16.6666666667%; }

.col-xs-offset-3 { margin-left: 25%; }

.col-xs-offset-4 { margin-left: 33.3333333333%; }

.col-xs-offset-5 { margin-left: 41.6666666667%; }

.col-xs-offset-6 { margin-left: 50%; }

.col-xs-offset-7 { margin-left: 58.3333333333%; }

.col-xs-offset-8 { margin-left: 66.6666666667%; }

.col-xs-offset-9 { margin-left: 75%; }

.col-xs-offset-10 { margin-left: 83.3333333333%; }

.col-xs-offset-11 { margin-left: 91.6666666667%; }

.col-xs-offset-12 { margin-left: 100%; }

@media (min-width: 768px) { .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 { float: left; }
  .col-sm-1 { width: 8.3333333333%; }
  .col-sm-2 { width: 16.6666666667%; }
  .col-sm-3 { width: 25%; }
  .col-sm-4 { width: 33.3333333333%; }
  .col-sm-5 { width: 41.6666666667%; }
  .col-sm-6 { width: 50%; }
  .col-sm-7 { width: 58.3333333333%; }
  .col-sm-8 { width: 66.6666666667%; }
  .col-sm-9 { width: 75%; }
  .col-sm-10 { width: 83.3333333333%; }
  .col-sm-11 { width: 91.6666666667%; }
  .col-sm-12 { width: 100%; }
  .col-sm-pull-0 { right: auto; }
  .col-sm-pull-1 { right: 8.3333333333%; }
  .col-sm-pull-2 { right: 16.6666666667%; }
  .col-sm-pull-3 { right: 25%; }
  .col-sm-pull-4 { right: 33.3333333333%; }
  .col-sm-pull-5 { right: 41.6666666667%; }
  .col-sm-pull-6 { right: 50%; }
  .col-sm-pull-7 { right: 58.3333333333%; }
  .col-sm-pull-8 { right: 66.6666666667%; }
  .col-sm-pull-9 { right: 75%; }
  .col-sm-pull-10 { right: 83.3333333333%; }
  .col-sm-pull-11 { right: 91.6666666667%; }
  .col-sm-pull-12 { right: 100%; }
  .col-sm-push-0 { left: auto; }
  .col-sm-push-1 { left: 8.3333333333%; }
  .col-sm-push-2 { left: 16.6666666667%; }
  .col-sm-push-3 { left: 25%; }
  .col-sm-push-4 { left: 33.3333333333%; }
  .col-sm-push-5 { left: 41.6666666667%; }
  .col-sm-push-6 { left: 50%; }
  .col-sm-push-7 { left: 58.3333333333%; }
  .col-sm-push-8 { left: 66.6666666667%; }
  .col-sm-push-9 { left: 75%; }
  .col-sm-push-10 { left: 83.3333333333%; }
  .col-sm-push-11 { left: 91.6666666667%; }
  .col-sm-push-12 { left: 100%; }
  .col-sm-offset-0 { margin-left: 0%; }
  .col-sm-offset-1 { margin-left: 8.3333333333%; }
  .col-sm-offset-2 { margin-left: 16.6666666667%; }
  .col-sm-offset-3 { margin-left: 25%; }
  .col-sm-offset-4 { margin-left: 33.3333333333%; }
  .col-sm-offset-5 { margin-left: 41.6666666667%; }
  .col-sm-offset-6 { margin-left: 50%; }
  .col-sm-offset-7 { margin-left: 58.3333333333%; }
  .col-sm-offset-8 { margin-left: 66.6666666667%; }
  .col-sm-offset-9 { margin-left: 75%; }
  .col-sm-offset-10 { margin-left: 83.3333333333%; }
  .col-sm-offset-11 { margin-left: 91.6666666667%; }
  .col-sm-offset-12 { margin-left: 100%; } }

@media (min-width: 992px) { .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 { float: left; }
  .col-md-1 { width: 8.3333333333%; }
  .col-md-2 { width: 16.6666666667%; }
  .col-md-3 { width: 25%; }
  .col-md-4 { width: 33.3333333333%; }
  .col-md-5 { width: 41.6666666667%; }
  .col-md-6 { width: 50%; }
  .col-md-7 { width: 58.3333333333%; }
  .col-md-8 { width: 66.6666666667%; }
  .col-md-9 { width: 75%; }
  .col-md-10 { width: 83.3333333333%; }
  .col-md-11 { width: 91.6666666667%; }
  .col-md-12 { width: 100%; }
  .col-md-pull-0 { right: auto; }
  .col-md-pull-1 { right: 8.3333333333%; }
  .col-md-pull-2 { right: 16.6666666667%; }
  .col-md-pull-3 { right: 25%; }
  .col-md-pull-4 { right: 33.3333333333%; }
  .col-md-pull-5 { right: 41.6666666667%; }
  .col-md-pull-6 { right: 50%; }
  .col-md-pull-7 { right: 58.3333333333%; }
  .col-md-pull-8 { right: 66.6666666667%; }
  .col-md-pull-9 { right: 75%; }
  .col-md-pull-10 { right: 83.3333333333%; }
  .col-md-pull-11 { right: 91.6666666667%; }
  .col-md-pull-12 { right: 100%; }
  .col-md-push-0 { left: auto; }
  .col-md-push-1 { left: 8.3333333333%; }
  .col-md-push-2 { left: 16.6666666667%; }
  .col-md-push-3 { left: 25%; }
  .col-md-push-4 { left: 33.3333333333%; }
  .col-md-push-5 { left: 41.6666666667%; }
  .col-md-push-6 { left: 50%; }
  .col-md-push-7 { left: 58.3333333333%; }
  .col-md-push-8 { left: 66.6666666667%; }
  .col-md-push-9 { left: 75%; }
  .col-md-push-10 { left: 83.3333333333%; }
  .col-md-push-11 { left: 91.6666666667%; }
  .col-md-push-12 { left: 100%; }
  .col-md-offset-0 { margin-left: 0%; }
  .col-md-offset-1 { margin-left: 8.3333333333%; }
  .col-md-offset-2 { margin-left: 16.6666666667%; }
  .col-md-offset-3 { margin-left: 25%; }
  .col-md-offset-4 { margin-left: 33.3333333333%; }
  .col-md-offset-5 { margin-left: 41.6666666667%; }
  .col-md-offset-6 { margin-left: 50%; }
  .col-md-offset-7 { margin-left: 58.3333333333%; }
  .col-md-offset-8 { margin-left: 66.6666666667%; }
  .col-md-offset-9 { margin-left: 75%; }
  .col-md-offset-10 { margin-left: 83.3333333333%; }
  .col-md-offset-11 { margin-left: 91.6666666667%; }
  .col-md-offset-12 { margin-left: 100%; } }

@media (min-width: 1200px) { .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 { float: left; }
  .col-lg-1 { width: 8.3333333333%; }
  .col-lg-2 { width: 16.6666666667%; }
  .col-lg-3 { width: 25%; }
  .col-lg-4 { width: 33.3333333333%; }
  .col-lg-5 { width: 41.6666666667%; }
  .col-lg-6 { width: 50%; }
  .col-lg-7 { width: 58.3333333333%; }
  .col-lg-8 { width: 66.6666666667%; }
  .col-lg-9 { width: 75%; }
  .col-lg-10 { width: 83.3333333333%; }
  .col-lg-11 { width: 91.6666666667%; }
  .col-lg-12 { width: 100%; }
  .col-lg-pull-0 { right: auto; }
  .col-lg-pull-1 { right: 8.3333333333%; }
  .col-lg-pull-2 { right: 16.6666666667%; }
  .col-lg-pull-3 { right: 25%; }
  .col-lg-pull-4 { right: 33.3333333333%; }
  .col-lg-pull-5 { right: 41.6666666667%; }
  .col-lg-pull-6 { right: 50%; }
  .col-lg-pull-7 { right: 58.3333333333%; }
  .col-lg-pull-8 { right: 66.6666666667%; }
  .col-lg-pull-9 { right: 75%; }
  .col-lg-pull-10 { right: 83.3333333333%; }
  .col-lg-pull-11 { right: 91.6666666667%; }
  .col-lg-pull-12 { right: 100%; }
  .col-lg-push-0 { left: auto; }
  .col-lg-push-1 { left: 8.3333333333%; }
  .col-lg-push-2 { left: 16.6666666667%; }
  .col-lg-push-3 { left: 25%; }
  .col-lg-push-4 { left: 33.3333333333%; }
  .col-lg-push-5 { left: 41.6666666667%; }
  .col-lg-push-6 { left: 50%; }
  .col-lg-push-7 { left: 58.3333333333%; }
  .col-lg-push-8 { left: 66.6666666667%; }
  .col-lg-push-9 { left: 75%; }
  .col-lg-push-10 { left: 83.3333333333%; }
  .col-lg-push-11 { left: 91.6666666667%; }
  .col-lg-push-12 { left: 100%; }
  .col-lg-offset-0 { margin-left: 0%; }
  .col-lg-offset-1 { margin-left: 8.3333333333%; }
  .col-lg-offset-2 { margin-left: 16.6666666667%; }
  .col-lg-offset-3 { margin-left: 25%; }
  .col-lg-offset-4 { margin-left: 33.3333333333%; }
  .col-lg-offset-5 { margin-left: 41.6666666667%; }
  .col-lg-offset-6 { margin-left: 50%; }
  .col-lg-offset-7 { margin-left: 58.3333333333%; }
  .col-lg-offset-8 { margin-left: 66.6666666667%; }
  .col-lg-offset-9 { margin-left: 75%; }
  .col-lg-offset-10 { margin-left: 83.3333333333%; }
  .col-lg-offset-11 { margin-left: 91.6666666667%; }
  .col-lg-offset-12 { margin-left: 100%; } }

.page-header h1, .page-header .h1, h1.header, .h1.header, header.h1 { font-size: 2.5555555556em; }

.page-header h2, .page-header .h2, h2.header, .h2.header, header.h2 { font-size: 2.0212218988em; }

.page-header h3, .page-header .h3, h3.header, .h3.header, header.h3 { font-size: 1.5986105078em; }

.page-header h4, .page-header .h4, h4.header, .h4.header, header.h4 { font-size: 1.2643616997em; }

.page-header h5, .page-header .h5, h5.header, .h5.header, header.h5 { font-size: 1.5986105078em; }

.page-header h6, .page-header .h6, h6.header, .h6.header, header.h6 { font-size: 0.7909129169em; }

.page-header { padding-bottom: 1px; padding-top: 1px; }

.breadcrumb + .page-header { margin-top: -28px; }

.page-header h1, .page-header .h1 { margin-bottom: 0.2992327366em; margin-top: 0.621483376em; }

.page-header h2, .page-header .h2 { margin-bottom: 0.3783384114em; margin-top: 0.7857797776em; }

.page-header h3, .page-header .h3 { margin-bottom: 0.478356597em; margin-top: 0.9935098552em; }

.page-header h4, .page-header .h4 { margin-bottom: 0.60481576em; margin-top: 0.60481576em; }

.page-header h5, .page-header .h5 { margin-bottom: 0.478356597em; margin-top: 0.478356597em; }

.page-header h6, .page-header .h6 { margin-bottom: 0.9668648292em; margin-top: 0.9668648292em; }

.page-header h1, .page-header h2, .page-header h3, .page-header h4, .page-header h5, .page-header h6, .page-header .h1, .page-header .h2, .page-header .h3, .page-header .h4, .page-header .h5, .page-header .h6 { line-height: 1; padding-bottom: 0.125em; }

.page-header h1, .page-header h2, .page-header h3, .page-header .h1, .page-header .h2, .page-header .h3 { padding-top: 0.25em; }

.page-header h4, .page-header h5, .page-header h6, .page-header .h4, .page-header .h5, .page-header .h6 { padding-top: 0.125em; }

.bg-venice_blue { background-color: #005593; }

a.bg-venice_blue:hover, a.bg-venice_blue:focus { background-color: #003760; }

.bg-endeavour { background-color: #0064ad; }

a.bg-endeavour:hover, a.bg-endeavour:focus { background-color: #00477a; }

.bg-corn { background-color: #edcd00; }

a.bg-corn:hover, a.bg-corn:focus { background-color: #baa100; }

.bg-gold_tips { background-color: #d2b91b; }

a.bg-gold_tips:hover, a.bg-gold_tips:focus { background-color: #a59115; }

.bg-pearl_bush { background-color: #e5ddd5; }

a.bg-pearl_bush:hover, a.bg-pearl_bush:focus { background-color: #d2c4b6; }

.bg-white { background: white; }

@media (min-width: 1360px) { .container { width: 1318px; } }

@media (min-width: 1600px) { .container.larger, .container.largest { width: 1568px; } }

@media (min-width: 1910px) { .container.largest { width: 1838px; } }

@media (min-width: 1360px) { .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 { float: left; }
  .col-xl-1 { width: 8.3333333333%; }
  .col-xl-2 { width: 16.6666666667%; }
  .col-xl-3 { width: 25%; }
  .col-xl-4 { width: 33.3333333333%; }
  .col-xl-5 { width: 41.6666666667%; }
  .col-xl-6 { width: 50%; }
  .col-xl-7 { width: 58.3333333333%; }
  .col-xl-8 { width: 66.6666666667%; }
  .col-xl-9 { width: 75%; }
  .col-xl-10 { width: 83.3333333333%; }
  .col-xl-11 { width: 91.6666666667%; }
  .col-xl-12 { width: 100%; }
  .col-xl-pull-0 { right: auto; }
  .col-xl-pull-1 { right: 8.3333333333%; }
  .col-xl-pull-2 { right: 16.6666666667%; }
  .col-xl-pull-3 { right: 25%; }
  .col-xl-pull-4 { right: 33.3333333333%; }
  .col-xl-pull-5 { right: 41.6666666667%; }
  .col-xl-pull-6 { right: 50%; }
  .col-xl-pull-7 { right: 58.3333333333%; }
  .col-xl-pull-8 { right: 66.6666666667%; }
  .col-xl-pull-9 { right: 75%; }
  .col-xl-pull-10 { right: 83.3333333333%; }
  .col-xl-pull-11 { right: 91.6666666667%; }
  .col-xl-pull-12 { right: 100%; }
  .col-xl-push-0 { left: auto; }
  .col-xl-push-1 { left: 8.3333333333%; }
  .col-xl-push-2 { left: 16.6666666667%; }
  .col-xl-push-3 { left: 25%; }
  .col-xl-push-4 { left: 33.3333333333%; }
  .col-xl-push-5 { left: 41.6666666667%; }
  .col-xl-push-6 { left: 50%; }
  .col-xl-push-7 { left: 58.3333333333%; }
  .col-xl-push-8 { left: 66.6666666667%; }
  .col-xl-push-9 { left: 75%; }
  .col-xl-push-10 { left: 83.3333333333%; }
  .col-xl-push-11 { left: 91.6666666667%; }
  .col-xl-push-12 { left: 100%; }
  .col-xl-offset-0 { margin-left: 0%; }
  .col-xl-offset-1 { margin-left: 8.3333333333%; }
  .col-xl-offset-2 { margin-left: 16.6666666667%; }
  .col-xl-offset-3 { margin-left: 25%; }
  .col-xl-offset-4 { margin-left: 33.3333333333%; }
  .col-xl-offset-5 { margin-left: 41.6666666667%; }
  .col-xl-offset-6 { margin-left: 50%; }
  .col-xl-offset-7 { margin-left: 58.3333333333%; }
  .col-xl-offset-8 { margin-left: 66.6666666667%; }
  .col-xl-offset-9 { margin-left: 75%; }
  .col-xl-offset-10 { margin-left: 83.3333333333%; }
  .col-xl-offset-11 { margin-left: 91.6666666667%; }
  .col-xl-offset-12 { margin-left: 100%; } }

@media (min-width: 1600px) { .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12 { float: left; }
  .col-xxl-1 { width: 8.3333333333%; }
  .col-xxl-2 { width: 16.6666666667%; }
  .col-xxl-3 { width: 25%; }
  .col-xxl-4 { width: 33.3333333333%; }
  .col-xxl-5 { width: 41.6666666667%; }
  .col-xxl-6 { width: 50%; }
  .col-xxl-7 { width: 58.3333333333%; }
  .col-xxl-8 { width: 66.6666666667%; }
  .col-xxl-9 { width: 75%; }
  .col-xxl-10 { width: 83.3333333333%; }
  .col-xxl-11 { width: 91.6666666667%; }
  .col-xxl-12 { width: 100%; }
  .col-xxl-pull-0 { right: auto; }
  .col-xxl-pull-1 { right: 8.3333333333%; }
  .col-xxl-pull-2 { right: 16.6666666667%; }
  .col-xxl-pull-3 { right: 25%; }
  .col-xxl-pull-4 { right: 33.3333333333%; }
  .col-xxl-pull-5 { right: 41.6666666667%; }
  .col-xxl-pull-6 { right: 50%; }
  .col-xxl-pull-7 { right: 58.3333333333%; }
  .col-xxl-pull-8 { right: 66.6666666667%; }
  .col-xxl-pull-9 { right: 75%; }
  .col-xxl-pull-10 { right: 83.3333333333%; }
  .col-xxl-pull-11 { right: 91.6666666667%; }
  .col-xxl-pull-12 { right: 100%; }
  .col-xxl-push-0 { left: auto; }
  .col-xxl-push-1 { left: 8.3333333333%; }
  .col-xxl-push-2 { left: 16.6666666667%; }
  .col-xxl-push-3 { left: 25%; }
  .col-xxl-push-4 { left: 33.3333333333%; }
  .col-xxl-push-5 { left: 41.6666666667%; }
  .col-xxl-push-6 { left: 50%; }
  .col-xxl-push-7 { left: 58.3333333333%; }
  .col-xxl-push-8 { left: 66.6666666667%; }
  .col-xxl-push-9 { left: 75%; }
  .col-xxl-push-10 { left: 83.3333333333%; }
  .col-xxl-push-11 { left: 91.6666666667%; }
  .col-xxl-push-12 { left: 100%; }
  .col-xxl-offset-0 { margin-left: 0%; }
  .col-xxl-offset-1 { margin-left: 8.3333333333%; }
  .col-xxl-offset-2 { margin-left: 16.6666666667%; }
  .col-xxl-offset-3 { margin-left: 25%; }
  .col-xxl-offset-4 { margin-left: 33.3333333333%; }
  .col-xxl-offset-5 { margin-left: 41.6666666667%; }
  .col-xxl-offset-6 { margin-left: 50%; }
  .col-xxl-offset-7 { margin-left: 58.3333333333%; }
  .col-xxl-offset-8 { margin-left: 66.6666666667%; }
  .col-xxl-offset-9 { margin-left: 75%; }
  .col-xxl-offset-10 { margin-left: 83.3333333333%; }
  .col-xxl-offset-11 { margin-left: 91.6666666667%; }
  .col-xxl-offset-12 { margin-left: 100%; } }

body, #footer .footer-elements-groups { display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flexbox; display: flex; }

.text-white { color: white; }

.flex.align-left, .inline-flex.align-left { justify-content: flex-start; }

.flex.align-right, .inline-flex.align-right { justify-content: flex-end; }

.flex.align-center, .inline-flex.align-center { justify-content: center; }

.flex.align-top, .inline-flex.align-top { align-items: flex-start; }

.flex.align-bottom, .inline-flex.align-bottom { align-items: flex-end; }

.flex.align-middle, .inline-flex.align-middle { align-items: center; }

.flex.align-justify, .inline-flex.align-justify { justify-content: space-between; }

.flex.align-spaced, .inline-flex.align-spaced { justify-content: space-around; }

.flex.column, .inline-flex.column { flex-direction: column; }

.flex.column.align-top, .inline-flex.column.align-top { justify-content: flex-start; align-items: stretch; }

.flex.column.align-bottom, .inline-flex.column.align-bottom { justify-content: flex-end; align-items: stretch; }

.flex.column.align-middle, .inline-flex.column.align-middle { justify-content: center; align-items: stretch; }

.flex.column.align-center, .inline-flex.column.align-center { align-items: center; }

.flex.column.align-left, .inline-flex.column.align-left { align-items: flex-start; }

.flex.column.align-right, .inline-flex.column.align-right { align-items: flex-end; }

.flex.column.reverse, .inline-flex.column.reverse { flex-direction: column-reverse; }

.flex.column.reverse.align-top, .inline-flex.column.reverse.align-top { justify-content: flex-end; }

.flex.column.reverse.align-bottom, .inline-flex.column.reverse.align-bottom { justify-content: flex-start; }

.flex.reverse, .inline-flex.reverse { flex-direction: row-reverse; }

.flex.reverse.align-left, .inline-flex.reverse.align-left { justify-content: flex-end; }

.flex.reverse.align-right, .inline-flex.reverse.align-right { justify-content: flex-start; }

.flexbox .flex.container:before, .flexbox .flex.container:after, .flexboxtweener .flex.container:before, .flexboxtweener .flex.container:after, .flexboxlegacy .flex.container:before, .flexboxlegacy .flex.container:after, .flexbox .flex.row:before, .flexbox .flex.row:after, .flexboxtweener .flex.row:before, .flexboxtweener .flex.row:after, .flexboxlegacy .flex.row:before, .flexboxlegacy .flex.row:after, .flexbox .inline-flex.container:before, .flexbox .inline-flex.container:after, .flexboxtweener .inline-flex.container:before, .flexboxtweener .inline-flex.container:after, .flexboxlegacy .inline-flex.container:before, .flexboxlegacy .inline-flex.container:after, .flexbox .inline-flex.row:before, .flexbox .inline-flex.row:after, .flexboxtweener .inline-flex.row:before, .flexboxtweener .inline-flex.row:after, .flexboxlegacy .inline-flex.row:before, .flexboxlegacy .inline-flex.row:after { content: none; }

.flex.wrap, .inline-flex.wrap { flex-wrap: wrap; }

.flexbox.no-flexwrap .flex.wrap.row, .flexbox.no-flexwrap .inline-flex.wrap.row { display: block; }

.flexbox.no-flexwrap .flex.wrap.row:before, .flexbox.no-flexwrap .flex.wrap.row:after, .flexbox.no-flexwrap .inline-flex.wrap.row:before, .flexbox.no-flexwrap .inline-flex.wrap.row:after { content: " "; display: table; }

.flexbox.no-flexwrap .flex.wrap.row:after, .flexbox.no-flexwrap .inline-flex.wrap.row:after { clear: both; }

.flex.wrap-reverse, .inline-flex.wrap-reverse { flex-wrap: wrap-reverse; }

.flexbox.no-flexwrap .flex.wrap-reverse.row, .flexbox.no-flexwrap .inline-flex.wrap-reverse.row { display: block; }

.flexbox.no-flexwrap .flex.wrap-reverse.row:before, .flexbox.no-flexwrap .flex.wrap-reverse.row:after, .flexbox.no-flexwrap .inline-flex.wrap-reverse.row:before, .flexbox.no-flexwrap .inline-flex.wrap-reverse.row:after { content: " "; display: table; }

.flexbox.no-flexwrap .flex.wrap-reverse.row:after, .flexbox.no-flexwrap .inline-flex.wrap-reverse.row:after { clear: both; }

.flex { display: flex; }

.inline-flex { display: inline-flex; }

body { flex-direction: column; }

.no-flexbox.flexboxlegacy body { display: block; }

body > pre { flex: 0 0 auto; }

.flex #Top, .inline-flex #Top { flex: 0 0 auto; }

.flex .main, .inline-flex .main { flex: 1 0 auto; }

body, #footer .footer-elements-groups { display: flex; }

.flexible { flex: 1 1; }

.inflexible, #footer { flex: 0; flex-shrink: 0; }

.growable { flex: 1 0; }

.shrinkable { flex: 0 1; }

.relative.flexible, .relative.inflexible, .relative.growable, .relative.shrinkable, #footer { flex-basis: auto; }

.absolute.flexible, .absolute.inflexible, .absolute.growable, .absolute.shrinkable { flex-basis: 0; }

.fluid { width: 100%; margin-left: auto; margin-right: auto; }

.fluid.large { max-width: 1280px; }

.fluid.larger { max-width: 1530px; }

.fluid.largest { max-width: 1937px; }

#footer { background-color: #005593; color: white; font-family: 'Oxygen', sans-serif; line-height: 1.1666666667; position: relative; z-index: 10; }

#footer address { margin-bottom: 0; }

#footer address a { color: inherit; }

#footer address a:hover, #footer address a:focus { color: inherit; }

#footer .footer-block { float: left; position: relative; }

#footer .footer-elements-groups.horizontal { flex-direction: row; }

.no-flexboxlegacy #footer .footer-elements-groups.horizontal:before, .no-flexboxlegacy #footer .footer-elements-groups.horizontal:after { content: " "; display: table; }

.no-flexboxlegacy #footer .footer-elements-groups.horizontal:after { clear: both; }

.no-flexboxlegacy #footer .footer-elements-groups.horizontal > * { float: left; }

#footer .footer-elements-groups.vertical { flex-direction: column; }

.no-flexboxlegacy #footer .footer-elements-groups.vertical { display: block; }

.no-flexboxlegacy #footer .footer-elements-groups.vertical:before, .no-flexboxlegacy #footer .footer-elements-groups.vertical:after { content: " "; display: table; }

.no-flexboxlegacy #footer .footer-elements-groups.vertical:after { clear: both; }

.no-flexboxlegacy #footer .footer-elements-groups.vertical > * { display: block; }

article p { text-align: justify; word-wrap: break-word; -webkit-hyphens: auto; -moz-hyphens: auto; -ms-hyphens: auto; -o-hyphens: auto; hyphens: auto; }

.text-center .img-responsive { margin-left: auto; margin-right: auto; }

.text-ellipis { white-space: nowrap; overflow: hidden; -ms-text-overflow: ellipsis; -o-text-overflow: ellipsis; text-overflow: ellipsis; }

.text-shadowen { color: white; text-shadow: 0px 0px 15px black, 0px 0px 5px rgba(0, 0, 0, 0.75), -1px -1px rgba(0, 0, 0, 0.25), 1px 1px rgba(0, 0, 0, 0.75); }

.text-small-caps { font-variant: small-caps; }

.text-venice_blue { color: #005593; }

a.text-venice_blue:hover, a.text-venice_blue:focus { color: #003760; }

.text-endeavour { color: #0064ad; }

a.text-endeavour:hover, a.text-endeavour:focus { color: #00477a; }

.text-corn { color: #edcd00; }

a.text-corn:hover, a.text-corn:focus { color: #baa100; }

.text-gold_tips { color: #d2b91b; }

a.text-gold_tips:hover, a.text-gold_tips:focus { color: #a59115; }

.text-pearl_bush { color: #e5ddd5; }

a.text-pearl_bush:hover, a.text-pearl_bush:focus { color: #d2c4b6; }

h1, .h1 { font-size: 1.4694444444em; }

h2, .h2 { font-size: 1.1622025918em; }

h3, .h3 { font-size: 0.919201042em; }

h4, .h4 { font-size: 0.7270079774em; }

h5, .h5 { font-size: 0.919201042em; }

h6, .h6 { font-size: 0.4547749272em; }

h1, .h1 { font-size: 2.1722222222em; }

h2, .h2 { font-size: 1.718038614em; }

h3, .h3 { font-size: 1.3588189316em; }

h4, .h4 { font-size: 1.0747074448em; }

h5, .h5 { font-size: 1.3588189316em; }

h6, .h6 { font-size: 0.6722759794em; }

@media (min-width: 992px) { h1, .h1 { font-size: 2.5555555556em; } }

@media (min-width: 992px) { h2, .h2 { font-size: 2.0212218988em; } }

@media (min-width: 992px) { h3, .h3 { font-size: 1.5986105078em; } }

@media (min-width: 992px) { h4, .h4 { font-size: 1.2643616997em; } }

@media (min-width: 992px) { h5, .h5 { font-size: 1.5986105078em; } }

@media (min-width: 992px) { h6, .h6 { font-size: 0.7909129169em; } }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { margin-top: 1.1em; }

h1.great, h2.great, h3.great, h4.great, h5.great, h6.great, .h1.great, .h2.great, .h3.great, .h4.great, .h5.great, .h6.great { margin-top: 2.2em; }

h1.humble, h2.humble, h3.humble, h4.humble, h5.humble, h6.humble, .h1.humble, .h2.humble, .h3.humble, .h4.humble, .h5.humble, .h6.humble { margin-top: 0.55em; }

h1.zero-top, h2.zero-top, h3.zero-top, h4.zero-top, h5.zero-top, h6.zero-top, .h1.zero-top, .h2.zero-top, .h3.zero-top, .h4.zero-top, .h5.zero-top, .h6.zero-top { margin-top: 0 !important; }

h1.zero-bottom, h2.zero-bottom, h3.zero-bottom, h4.zero-bottom, h5.zero-bottom, h6.zero-bottom, .h1.zero-bottom, .h2.zero-bottom, .h3.zero-bottom, .h4.zero-bottom, .h5.zero-bottom, .h6.zero-bottom { margin-bottom: 0 !important; }

h1 > .subheading, h2 > .subheading, h3 > .subheading, h4 > .subheading, h5 > .subheading, h6 > .subheading, .h1 > .subheading, .h2 > .subheading, .h3 > .subheading, .h4 > .subheading, .h5 > .subheading, .h6 > .subheading { display: table; margin: auto; font-weight: inherit; color: #6699be; }

h1, h2, h3, .h1, .h2, .h3 { margin-bottom: 0.55em; }

h1.balanced, h2.balanced, h3.balanced, .h1.balanced, .h2.balanced, .h3.balanced { margin-top: 0.55em; }

h1.great, h2.great, h3.great, .h1.great, .h2.great, .h3.great { margin-bottom: 1.1em; }

h1.great.balanced, h2.great.balanced, h3.great.balanced, .h1.great.balanced, .h2.great.balanced, .h3.great.balanced { margin-top: 1.1em; }

h1.humble, h2.humble, h3.humble, .h1.humble, .h2.humble, .h3.humble { margin-bottom: 0.275em; }

h1.humble.balanced, h2.humble.balanced, h3.humble.balanced, .h1.humble.balanced, .h2.humble.balanced, .h3.humble.balanced { margin-top: 0.275em; }

h4, h5, h6, .h4, .h5, .h6 { margin-bottom: 1.1em; }

h4.great, h5.great, h6.great, .h4.great, .h5.great, .h6.great { margin-bottom: 2.2em; }

h4.humble, h5.humble, h6.humble, .h4.humble, .h5.humble, .h6.humble { margin-bottom: 0.55em; }

.lead { font-weight: normal; font-size: 1.5em; margin-bottom: 0.5490196078em; }

#footer { box-shadow: 0px 20px 40px -20px rgba(26, 25, 25, 0.666667) inset; }

#footer .credits { position: relative; padding-top: 0.6785714286em; padding-bottom: 0.6785714286em; text-align: center; background-color: #d2b91b; font-size: 0.8235294118em; }

#footer .credits a, #footer .credits a:hover, #footer .credits a:focus { color: inherit; }

#footer .credits img { width: 100px; height: 26px; }

#footer .credits .disclaimers { opacity: 0.85; }

#footer .credits .disclaimers a { margin-left: 1.1764705882em; }

#footer address { line-height: inherit; }

#Contacts { padding-top: 0.8235294118em; padding-bottom: 0.8235294118em; }

#Contacts .contact-element { margin-bottom: 0.8235294118em; letter-spacing: 1px; }

#Contacts .contact-element.organization { margin-top: 0.8235294118em; }

#Contacts .contact-element > .icon { flex: 0 0 auto; font-size: 1.6em; width: 1.75em; text-align: center; }

#Contacts .contact-element > .flex.column strong:not(.org), #Contacts .contact-element > .flex.column small.owner { font-size: 0.7647058824em; opacity: 0.85; }

#Contacts .contact-element > .flex.column .org { text-transform: uppercase; font-size: 1.0294117647em; }

html { font-size: 17px; }

body { font-size: 1em; height: 100vh; }

.logo { background: #004476; text-align: center; margin: 0; padding: 10px 16px; }

.logo img { width: 11.5183246073vw; }

@media (min-width: 1910px) { .logo img { width: 220px; } }

@media (max-width: 991px) { .logo img { width: 114.261780105px; } }

.page-header .subtitle { width: fit-content; margin: 0.5em auto 1em; border-top: solid 1px #ecc6c2; box-shadow: 0px 1px 0px white inset; padding-top: 0.5em; }

#BackToHome { display: block; margin-top: 2rem; }

#footer { flex: 0 0 auto; }

#Credits .container:before, #Credits .container:after { content: none; }

/*# sourceMappingURL=error.css.map */